import type { CollectionFilter } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import * as _ from 'lodash-es';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { SearchLoading } from 'src/common/components/list-view/search-loading';
import { LoadingIcon } from 'src/common/components/loading-icon';
import type { SearchItem } from 'src/components/search';
import { useSearchUI } from 'src/components/search/use-search-ui';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { useBitable } from '../context';

export const SelectRecord: FC<{
  propertyId: string;
  path: (string | number)[];
  onChange?: (value: string) => void;
}> = ({ onChange, propertyId, path }) => {
  const { collectionId, viewId } = useBitable();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState('');
  const { propertySchema, targetPropertySchema } = usePropertySchema(collectionId, propertyId);

  const reallyCollectionId =
    propertySchema?.type === CollectionSchemaType.RELATION
      ? propertySchema.collectionId ?? ''
      : targetPropertySchema?.collectionId ?? '';
  const { resultList, onInputChange, firstTime, loadMore, hasMore, loading } = useSearchUI({
    type: 'collection',
    fileType: undefined,
    source: 'relationMenu',
    parentId: reallyCollectionId,
    canSearchWithEmpty: true,
    getPerPage: () => 20,
  });

  useEffect(() => onInputChange(''), [onInputChange]);

  const ids = useObservableStore(
    ({ blocks, collectionViews }) => {
      const view = collectionViews[viewId];
      const filter = _.get(view?.format.filter, path) as CollectionFilter | undefined;
      const schema = blocks[collectionId]?.data.schema;
      if (!schema || !filter) return [];
      const value = filter?.value;
      return (
        value
          ?.split(',')
          .filter((uuid) => blocks[uuid] && blocks[reallyCollectionId]?.subNodes.includes(uuid)) ??
        []
      );
    },
    [viewId, collectionId]
  );

  const items = useMemo(() => {
    return resultList.map((searchItem: SearchItem) => ({
      type: ListItemType.SEARCH,
      data: {
        uuid: searchItem.uuid,
        title: searchItem.title || getUntitledName(searchItem.type),
        icon: searchItem.data.icon,
        type: searchItem.type,
        renderRight: () => {
          if (ids.includes(searchItem.uuid)) {
            return <Icon name="IcCheck02" size="middle" />;
          }
          return null;
        },
      },
    }));
  }, [ids, resultList]);

  useEffect(() => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      inputRef.current?.focus();
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, [ids]);

  const onSelect = (item: any) => {
    const index = ids.indexOf(item.data.uuid);
    if (index !== -1) {
      ids.splice(index, 1);
    } else {
      ids.push(item.data.uuid);
    }
    onChange?.(ids.join(','));
  };

  const onDelete = (uuid: string) => {
    const index = ids.indexOf(uuid);
    if (index !== -1) {
      ids.splice(index, 1);
    }
    onChange?.(ids.join(','));
  };

  const deleteLastSelectedOption = () => {
    ids.pop();
    onChange?.(ids.join(','));
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (isHotkey('Esc')(event)) {
      return;
    }

    if (!inputValue && isHotkey('Backspace')(event)) {
      deleteLastSelectedOption();
    }
  };

  const clearAll = () => {
    onChange?.('');
    onInputChange('');
    setInputValue('');
  };

  if (!reallyCollectionId) return null;
  return (
    <div className="relative overflow-hidden next-modal w-60">
      <div className="relative m-2 border-2 border-black rounded p-[5px] flex justify-between items-start min-h-[32px]">
        <div className="flex flex-wrap w-[190px] overflow-hidden">
          {ids.map((uuid) => {
            return (
              <div className="inline-flex items-center text-t4 " key={uuid}>
                <InlinePage uuid={uuid} className="p-0 leading-[22px] my-px" />

                <button
                  className="opacity-80 flex-shrink-0 w-5 h-5 inline-block"
                  onClick={() => onDelete(uuid)}
                >
                  <Icon name="IcToastClose" className="w-full h-full" size="auto" />
                </button>
              </div>
            );
          })}
          <AutoHeightTextArea
            ref={inputRef}
            autoFocus
            singleLine
            placeholder="搜索记录"
            fontClassName={'leading-5 text-[14px] whitespace-nowrap'}
            className={'w-full placeholder:text-grey4'}
            boxClassName={'flex-1 flex-shrink-0 min-w-[120px]'}
            value={inputValue}
            onKeyDown={handleInputKeyDown}
            onChange={(event) => {
              const { value } = event.target;
              onInputChange(value);
              setInputValue(value);
            }}
          />
        </div>

        <Icon
          size="middle"
          name="IcUploadCancel"
          onClick={clearAll}
          className="text-grey4 cursor-pointer animate-hover"
        />
      </div>

      <ListView
        items={items}
        className="relative h-60"
        hasMore={hasMore}
        loadMore={loadMore}
        scrollContainerStyle={{ top: '0px' }}
        onItemClick={onSelect}
        loadingView={loading && <SearchLoading />}
        customFooter={
          <>
            {loading && hasMore && (
              <div className="relative flex flex-row items-center justify-center h-10">
                <LoadingIcon size="middle" />
                <div className="flex items-center ml-2 text-center text-t2 text-grey4">
                  正在加载...
                </div>
              </div>
            )}
            {!firstTime && !loading && items.length === 0 && (
              <div className="relative flex justify-center items-center h-10 text-t2 text-grey4">
                没有找到相关结果
              </div>
            )}
          </>
        }
      />
    </div>
  );
};
