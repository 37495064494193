import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { useAtomValue } from 'jotai';
import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useOpenAiEditorFromBitable } from 'src/editor/editor/uikit/ai-editor/use-open-ai-from-bitable';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { updateCollectionView } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { Modals } from 'src/modals';
import { getState } from 'src/redux/store';
import { setAppUiState } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { parseRightPageId, useIsInRight, useMasterHistory } from 'src/utils/right-utils';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { TableClassName } from '../const';
import { useBitable } from '../context';
import { $highlightProperty } from '../form-view/form-logic-atoms';
import { useOpenPreViewForm } from '../form-view/share-form-view';
import { useOpenShareFormSetting } from '../form-view/use-open-share-form-setting';
import { isFilterEnabled } from '../table-view/body/filters';
import { PropertyManagePlace } from '../table-view/types';
import { useOpenPropertyWidget } from '../table-view/widgets/property';
import { PAGE_MANAGER_HEIGHT } from '../timeline-view/const';
import { BitableSearch } from './bitable-search';
import { ConfigTableFilter } from './config-table-filter';
import { ConfigTableSort } from './config-table-sort';
import { GroupList } from './group-list';
import { getValidPropertyIds } from './group-list/utils';
import { NoDateRecords, useNoDateRecord } from './no-date-record';
import { PageOptionsMenu } from './options-menu';
import { TemplatePages } from './template-pages';
import { ViewsManager } from './view-list-manager';
import { ItemType, ViewSettingPanel } from './view-setting-panel';

interface Props {
  className?: string;
}

export const PageManager: FC<Props> = ({ className }) => {
  const {
    viewId,
    managerReadonly,
    readonly,
    viewType,
    pageManager,
    isLocked,
    collectionId,
    viewParentId,
  } = useBitable();
  const { enableAI } = useEnableAI();
  const openAIEditor = useOpenAiEditorFromBitable();
  const isRight = useIsInRight();
  const view = useCollectionView(viewId);
  const formIllegal = view?.format.formIllegal;
  const noDateRecord = useNoDateRecord();
  const { filterEnabled, sortersEnabled, groupEnabled, subGroupEnabled } = useObservableStore(
    (state) => {
      const viewInfo = getViewFormat(viewId, state.blocks, state.collectionViews);
      if (!viewInfo) return {};
      const { view, collection, subGroupBy, groupBy } = viewInfo;

      const filterEnabled = isFilterEnabled(view.format.filter, collection.data.schema);

      const sorters = view.format.sorters ?? [];
      const sortersEnabled = sorters.filter((it) => !it.disable).length > 0;

      const allIds = getValidPropertyIds(collectionId, state);
      const groupEnabled = allIds.includes(groupBy?.property ?? '');
      const subGroupEnabled = allIds.includes(subGroupBy?.property ?? '');

      return { filterEnabled, sortersEnabled, groupEnabled, subGroupEnabled };
    },
    [collectionId, viewId]
  );

  const openModal = useOpenModal();
  const isForm = CollectionViewType.FORM === view?.type;
  const isTable = CollectionViewType.TABLE === view?.type;
  const formLogicHighlightProperty = useAtomValue($highlightProperty);

  const viewTitleRef = useRef(view?.title ?? '');
  useEffect(() => {
    viewTitleRef.current = view?.title ?? '';
  }, [view?.title]);

  const showGroups = (event: MouseEvent, isSubGroup = false) => {
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(event.currentTarget),
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <GroupList isSubGroup={isSubGroup} />;
      },
    });
  };

  const showConfigTableSort = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(event.currentTarget),
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <ConfigTableSort />;
      },
    });
  };

  const showConfigTableFilter = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(event.currentTarget),
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <ConfigTableFilter />;
      },
    });
  };

  const showOptionMenu = (event: MouseEvent) => {
    openModal.dropdown({
      modalId: Modals.BITABLE_OPTION_MENU,
      popcorn: event.currentTarget,
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content() {
        return <PageOptionsMenu />;
      },
    });
  };

  const openEditor = () => {
    const popcorn = querySelectorFromMainContent('.block-content', isRight);
    if (!popcorn) return;
    openAIEditor(viewParentId, popcorn);
  };

  const showRecords = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      content: ({ onCloseModal, popper }) => (
        <NoDateRecords closeModal={onCloseModal} popper={popper} />
      ),
    });
  };

  const viewSettingButtonRef = useRef<HTMLButtonElement>(null);
  // 直接使用 viewId 会导致在拷贝视图后更新标题的时候更新的是原来的视图标题，这样属于闭包的坑
  const viewIdRef = useRef(viewId);
  viewIdRef.current = viewId;
  const showViewSetting = (defaultItemType?: ItemType) => {
    openModal.dropdown({
      popcorn: viewSettingButtonRef.current ?? document.body,
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      closeBeforeCallBack: () => {
        const newTitle = viewTitleRef.current;
        const view = getState().collectionViews?.[viewIdRef.current];
        if (newTitle !== view?.title) {
          updateCollectionView(viewIdRef.current, { title: newTitle });
        }
      },
      content: ({ onCloseModal }) => (
        <ViewSettingPanel
          viewTitleRef={viewTitleRef}
          defaultItemType={defaultItemType}
          onCloseModal={onCloseModal}
        />
      ),
    });
  };

  useEffect(() => {
    if (!isForm) return;
    if (formLogicHighlightProperty) {
      showViewSetting(ItemType.FORM_LOGIC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForm, formLogicHighlightProperty]);

  const renderCreateRecordButton = useRenderCreateRecordButton();

  return (
    <div
      className={cx(
        'flex items-center justify-between bg-white1 sticky top-0 flex-shrink-0',
        className
      )}
      style={{ height: PAGE_MANAGER_HEIGHT }}
      ref={pageManager}
    >
      <ViewsManager />

      <div className={cx('flex gap-2', formIllegal && 'pointer-events-none opacity-30')}>
        {!managerReadonly ? (
          <>
            <BitableFormManager />

            {isTable && enableAI && (
              <button
                className="text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-[#00E384]"
                onClick={openEditor}
              >
                <Icon size="middle" className="mr-1" name="IcAi" />
              </button>
            )}

            <button
              ref={viewSettingButtonRef}
              className="text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3"
              onClick={() => showViewSetting()}
            >
              <Icon size="middle" className="mr-1" name="IcMenuSettings" />
              视图设置
            </button>

            {viewType === CollectionViewType.CALENDAR && noDateRecord && noDateRecord.length > 0 && (
              <button
                className="text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3"
                onClick={showRecords}
              >
                <Icon size="middle" className="mr-1" name="IcTitleDate" />
                无时间记录({noDateRecord.length})
              </button>
            )}

            {viewType &&
              groupEnabled &&
              ![CollectionViewType.CALENDAR, CollectionViewType.FORM].includes(viewType) && (
                <Tooltip popup="分组">
                  <button
                    className={cx(
                      'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color'
                    )}
                    onClick={(event) => showGroups(event)}
                  >
                    <Icon size="middle" name="IcGroup" />
                  </button>
                </Tooltip>
              )}

            {viewType === CollectionViewType.BOARD && subGroupEnabled && (
              <Tooltip popup="子分组">
                <button
                  className={cx(
                    'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color'
                  )}
                  onClick={(event) => showGroups(event, true)}
                >
                  <Icon size="middle" name="IcSubGroup" />
                </button>
              </Tooltip>
            )}

            {viewType && ![CollectionViewType.FORM].includes(viewType) && filterEnabled && (
              <Tooltip popup="筛选">
                <button
                  className={cx(
                    'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color'
                  )}
                  onClick={showConfigTableFilter}
                >
                  <Icon size="middle" name="IcFilter" />
                </button>
              </Tooltip>
            )}

            {viewType && ![CollectionViewType.FORM].includes(viewType) && sortersEnabled && (
              <Tooltip popup="排序">
                <button
                  className={cx(
                    'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-active_color',
                    TableClassName.tableSortManager
                  )}
                  onClick={showConfigTableSort}
                >
                  <Icon size="middle" name="IcDescending" />
                </button>
              </Tooltip>
            )}

            <BitableSearch />

            <button
              className={cx(
                'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3'
              )}
              onClick={showOptionMenu}
            >
              <Icon size="middle" name="IcMore" />
            </button>

            {!isLocked && <FormCreatePropertyButton />}
          </>
        ) : (
          <>
            <Tooltip popup="筛选">
              <button
                className={cx(
                  'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3',
                  filterEnabled && 'text-active_color'
                )}
                onClick={showConfigTableFilter}
              >
                <Icon size="middle" name="IcFilter" />
              </button>
            </Tooltip>

            <Tooltip popup="排序">
              <button
                className={cx(
                  'text-t2 animate-hover flex items-center whitespace-nowrap rounded p-1 text-grey3',
                  TableClassName.tableSortManager,
                  sortersEnabled && 'text-active_color'
                )}
                onClick={showConfigTableSort}
              >
                <Icon size="middle" name="IcDescending" />
              </button>
            </Tooltip>

            <BitableSearch />
          </>
        )}

        {!readonly &&
          viewType &&
          ![CollectionViewType.FORM].includes(viewType) &&
          renderCreateRecordButton()}
      </div>
    </div>
  );
};

export const useRenderCreateRecordButton = () => {
  const { viewId, collectionId } = useBitable();
  const openModal = useOpenModal();
  const insertRecord = useInsertRecordUI({ ignoreSorters: true });
  const createRecord = () => {
    void insertRecord({
      viewId,
      where: { first: true },
      openInRight: true,
    });
  };
  const showTemplates = useCallback(
    (element?: HTMLElement | null) => {
      if (element == null) {
        // eslint-disable-next-line no-console
        console.warn('showTemplates element == null');
        return;
      }
      openModal.dropdown({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        popcorn: element,
        placement: 'bottom',
        modalId: Modals.BITABLE_TEMPLATE_RECORDS,
        modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
        content: () => <TemplatePages />,
      });
    },
    [openModal]
  );
  const createRecordBtnRef = useRef<HTMLDivElement>(null);
  const masterHistory = useMasterHistory();
  useEffect(() => {
    let prevIsTemplatePage = false;
    const dispose = masterHistory.listen((location) => {
      const rightPageId = parseRightPageId(location.search);
      if (rightPageId == null) {
        if (prevIsTemplatePage) {
          prevIsTemplatePage = false;
          showTemplates(createRecordBtnRef.current);
        }
        return;
      }
      prevIsTemplatePage = false;
      const rightPage = getState().blocks[rightPageId];
      if (rightPage == null) return;
      const collection = getState().blocks[collectionId];
      if (collection == null) return;
      if (rightPage.isTemplate && collection.templatePages?.includes(rightPage.uuid)) {
        prevIsTemplatePage = true;
      }
    });
    return () => dispose();
  }, [collectionId, masterHistory, showTemplates]);

  return () => (
    <div className="flex" ref={createRecordBtnRef}>
      <button
        className={cx(
          'text-t2-medium flex items-center whitespace-nowrap rounded-l-l bg-active_color py-1 px-2 text-white hover:brightness-90'
        )}
        onClick={createRecord}
      >
        创建
      </button>
      <Tooltip popup="多维表模板">
        <button
          className={cx(
            'flex px-1 items-center justify-center whitespace-nowrap rounded-r bg-active_color py-1 text-white hover:brightness-90 border-l border-l-0.1'
          )}
          onClick={(event) => showTemplates(event.currentTarget)}
        >
          <Icon size="middle" name="IcArrowShow" />
        </button>
      </Tooltip>
    </div>
  );
};

export const BitableFormManager: FC = () => {
  const { viewId, readonly, collectionId, embed } = useBitable();
  const collectionView = useCollectionView(viewId);
  const openShareFormSetting = useOpenShareFormSetting();
  const { format, type } = collectionView || {};
  const openPreViewForm = useOpenPreViewForm();

  if (readonly || type !== CollectionViewType.FORM) return null;

  return (
    <>
      <Tooltip
        className={cx(
          'text-t2 animate-hover flex flex-shrink-0 items-center px-1',
          format?.formShared ? 'text-active_color' : 'text-grey3'
        )}
        onClick={(event) => {
          openShareFormSetting({
            popcorn: event.currentTarget,
            viewId,
            collectionId,
            placement: 'bottom',
          });
        }}
      >
        <Icon name="IcFormShare" size="middle" className="mr-1" />
        {format?.formShared ? '已开启分享' : '分享收集表'}
      </Tooltip>
      {!embed && (
        <Tooltip
          className={cx('text-t2 animate-hover flex flex-shrink-0 items-center px-1 text-grey3')}
          onClick={() => {
            openPreViewForm({ viewId, collectionId });
          }}
        >
          <Icon name="IcMenuItemHide" size="middle" className="mr-1" />
          预览
        </Tooltip>
      )}
    </>
  );
};

const FormCreatePropertyButton: FC = () => {
  const isInRight = useIsInRight();
  const { viewId, readonly, collectionId } = useBitable();
  const openPropertyWidget = useOpenPropertyWidget();
  const createProperty = useCreateProperty();
  const collectionView = useCollectionView(viewId);
  const { type } = collectionView || {};

  const handleCreateProperty = (event: MouseEvent) => {
    bizTracker.event('bitable_property_create', { from_scene: 'form' });
    const newPropertyId = createProperty({
      collectionId,
      viewId,
    });
    const spacePage = querySelectorFromMainContent(`.next-space-page`, isInRight) as HTMLElement;

    const contentPage = querySelectorFromMainContent(
      `.next-space-page .block-content`,
      isInRight
    ) as HTMLElement;

    if (newPropertyId) {
      setAppUiState({
        $newTableProperty: {
          propertyId: newPropertyId,
          from: PropertyManagePlace.FORM_PROPERTIES,
        },
      });

      openPropertyWidget(
        newPropertyId,
        event.currentTarget,
        PropertyManagePlace.FORM_PROPERTIES,
        'bottom-start',
        () => {
          spacePage.scrollTo({ top: contentPage.clientHeight, behavior: 'smooth' });
        }
      );
    }
  };

  if (readonly || type !== CollectionViewType.FORM) return null;

  return (
    <Button className="flex-shrink-0" colorType="active" onClick={handleCreateProperty}>
      创建
    </Button>
  );
};
