/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useRef, type FC } from 'react';
import { useChartBlockId } from './chart-config-context';
import { useChartFormat, useUpdateChartFormat } from 'src/hooks/chart';
import { MultiSwitchText } from './multi-switch-text';
import { AggregationAction } from '@next-space/fe-api-idl';
import type { YAxisProperty, ChartFormat } from '@next-space/fe-api-idl';
import { useSelectProperty } from './use-select-property';
import { Icon } from 'src/common/components/icon';
import { CalculateItem } from './calculate-item';
import { ChartLine } from '../chart-line';
import { getAllProperties } from 'src/utils/collection-util';
import { useStore } from 'react-redux';

/** 纵轴配置 */
const statisticsData: { statistics: ChartFormat['statistics']; desc: string }[] = [
  {
    statistics: 'record',
    desc: '统计记录总数',
  },
  {
    statistics: 'property',
    desc: '统计字段数值',
  },
];
export const defaultYAxisProperty: YAxisProperty = {
  propertyId: 'title',
  aggregation: AggregationAction.COUNT_ALL,
};

export const YAxisField: FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const chartBlockId = useChartBlockId();
  const format = useChartFormat(chartBlockId);
  const hasGroupAggregation = format?.groupAggregationPropertyId;
  const collectionId = format?.ref?.uuid;
  const statistics = format?.statistics ?? 'record';
  const statisticsValue = statistics === 'record' ? statisticsData[0]! : statisticsData[1]!;
  // 如果已经选取了collectionId，则默认使用标题，如果没有则为空
  const yAxisPropertyIds = format?.yAxisPropertyIds ?? (collectionId ? [defaultYAxisProperty] : []);
  const selectProperty = useSelectProperty();
  const store = useStore();
  const allPropertyLen = useMemo(() => {
    const allProperties = getAllProperties(store, collectionId ?? '') ?? [];
    return allProperties.length;
  }, [collectionId, store]);
  const isSelectAllProperty = yAxisPropertyIds.length === allPropertyLen;

  const updateChartFormat = useUpdateChartFormat();
  const isNumberChart = format?.chartMode?.type === 'Number';
  const isPieChart = format?.chartMode?.type === 'Pie';
  const scrollBottom = () => {
    setTimeout(() => {
      const scroller = divRef.current?.closest('.chart-config-scroller');
      if (scroller) {
        scroller.scrollTop = scroller.scrollHeight;
      }
    }, 10);
  };
  return (
    <>
      <ChartLine className="mt-4" />
      <div ref={divRef} className="mt-5 text-t2-medium">
        纵轴
      </div>
      <div className="text-t2 mt-2 space-y-3">
        <div className="flex items-center justify-between">
          <div>统计类型</div>
          <MultiSwitchText
            values={statisticsData}
            active={statisticsValue}
            onSwicth={(v) => {
              updateChartFormat(chartBlockId, {
                statistics: v.statistics,
              });
              scrollBottom();
            }}
          />
        </div>
        {statisticsValue.statistics === 'property' && (
          <>
            {yAxisPropertyIds.map((v, index) => {
              return <CalculateItem key={index} yAxisIndex={index} />;
            })}
            {!hasGroupAggregation &&
              yAxisPropertyIds.length > 0 &&
              !isNumberChart &&
              !isPieChart &&
              !isSelectAllProperty && (
                <div
                  className="flex items-center rounded-md animate-hover w-fit px-2 py-1.5"
                  onClick={(e) => {
                    if (!collectionId) return;
                    selectProperty(collectionId, {
                      popcorn: e.currentTarget,
                      callback: (propertyId) => {
                        updateChartFormat(chartBlockId, {
                          orderBy: 'xAxis', // 如果超过2个就设置为横轴排序
                          yAxisPropertyIds: [
                            ...yAxisPropertyIds,
                            {
                              propertyId,
                              aggregation: AggregationAction.COUNT_ALL,
                            },
                          ],
                        });
                        scrollBottom();
                      },
                      excludeIds: yAxisPropertyIds.map((v) => v.propertyId!),
                    });
                  }}
                >
                  <Icon name={'IcAdd'} size="middle" />
                  <div className="ml-0.5">添加字段</div>
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};
