import { createPersistStore, createSetState } from '@flowus/common/zustand/utils';
import { isElectron } from 'react-device-detect';

export const $localState = createPersistStore<{
  beta: boolean;
  /** 优惠券倒计时 */
  $showCountDown?: {
    [x: string]: {
      startTime?: number;
    };
  };
}>('$local-state', () => {
  return {
    beta: __HOST_LOCAL__ || __HOST_TEST__ || isElectron,
    $showCountDown: undefined,
  };
});

export const $setLocalState = createSetState($localState, { assign: true });

export const useLocalStateBeta = () => {
  return $localState((state) => state.beta);
};

export const useLocalState = $localState;

export const setSpaceCountDown = (spaceId: string, time?: number) => {
  $setLocalState((pre) => {
    return {
      ...pre,
      $showCountDown: { ...pre.$showCountDown, [spaceId]: { startTime: time } },
    };
  });
};
