import { cx } from '@flowus/common/cx';
import type { CollectionViewGroups } from '@next-space/fe-api-idl';
import {
  CollectionSchemaType,
  CollectionViewType,
  GroupLevel,
  GroupSortType,
} from '@next-space/fe-api-idl';
import { useDebounceFn } from 'ahooks';
import BigNumber from 'bignumber.js';
import type { FC, MouseEvent } from 'react';
import { useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { Switch } from 'src/common/components/switch';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useUpdateGroupProperty } from 'src/hooks/block/use-update-group-property';
import { EMPTY_GROUP_NAME } from 'src/hooks/collection-view/table-groups/const';
import { selectCollectionGroups } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useGroupBy } from 'src/hooks/collection-view/use-collection-view';
import {
  useEnableBoardColor,
  useHiddenEmptyColumns,
} from 'src/hooks/collection-view/use-enable-board-color';
import { getSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import { useGroupPropertySchema } from 'src/hooks/collection-view/use-group-option';
import { getState } from 'src/redux/store';
import type { NextCollectionView } from 'src/redux/types';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { ICON_MAP } from '../../const';
import { GroupLevelMap, GroupSortMap } from './const';
import { getGroupSchemaType } from './helper';
import { getGroupLevel, getGroupSort, getNumberGroupLevel, getValidPropertyIds } from './utils';

interface Props {
  isSubGroup?: boolean;
}

export const GroupSetting: FC<Props> = ({ isSubGroup }) => {
  const { viewId, viewType, isLocked, collectionId } = useBitable();
  const enableBoardColor = useEnableBoardColor(viewId);
  const hideEmptyGroups = useHiddenEmptyColumns(viewId, isSubGroup);
  const groupBy = useGroupBy(viewId, isSubGroup);
  const groupSchema = useGroupPropertySchema(viewId, isSubGroup);
  const openModal = useOpenModal();

  const isBoard = viewType === CollectionViewType.BOARD;

  const selectGroupProperty = (event: MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: () => {
        return <SelectGroupProperty isSubGroup={isSubGroup} />;
      },
    });
  };

  const selectGroupSort = (event: MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: () => <SelectGroupSort isSubGroup={isSubGroup} />,
    });
  };

  const selectGroupLevel = (event: MouseEvent) => {
    if (isLocked) return;

    const groupSchemaType = getGroupSchemaType(collectionId, groupBy?.property);

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: () => {
        if (groupSchemaType === CollectionSchemaType.NUMBER) {
          return <NumberGroupLevel isSubGroup={isSubGroup} />;
        }

        return <SelectGroupLevel isSubGroup={isSubGroup} />;
      },
    });
  };

  const switchHiddenEmptyGroup = (visible: boolean) => {
    if (isLocked) return;

    const {
      groups: oldGroups = [],
      subGroups: oldSubGroups = [],
      view,
    } = getViewFormat(viewId) ?? {};
    if (!view) return;

    const sortedRecordIds = getSortedRecordIds({ viewId });
    const { blocks, collectionViews, users } = getState();
    const result = selectCollectionGroups({
      blocks,
      collectionViews,
      users,
      viewId,
      sortedRecordIds,
    });
    if (!result) return;

    const { groups = [], withoutValidGroup, subGroups = [] } = result;
    if (!isSubGroup && withoutValidGroup) return;

    const currentGroups = isSubGroup ? subGroups : groups;
    let newGroups: CollectionViewGroups = [];
    if (visible) {
      const needHiddenGroup = new Set();
      currentGroups.forEach((group) => {
        if (group.recordIds.length === 0) {
          needHiddenGroup.add(group.value);
        }
      });

      newGroups = (isSubGroup ? oldSubGroups : oldGroups).map((group) => {
        if (
          (group.value && needHiddenGroup.has(group.value)) ||
          (!group.value && needHiddenGroup.has(EMPTY_GROUP_NAME))
        ) {
          return {
            ...group,
            emptyHidden: true,
          };
        }

        return { ...group, emptyHidden: undefined };
      });
    } else {
      newGroups = (isSubGroup ? oldSubGroups : oldGroups).map((group) => {
        return {
          ...group,
          emptyHidden: undefined,
        };
      });
    }

    const newGroupSetting: Partial<NextCollectionView['format']> = {};
    if (isBoard && !isSubGroup) {
      newGroupSetting.boardGroups = newGroups;
      newGroupSetting.boardGroupBy = {
        ...view.format.boardGroupBy,
        hideEmptyGroups: visible,
      };
    } else {
      newGroupSetting.collectionGroups = newGroups;
      newGroupSetting.collectionGroupBy = {
        ...view.format.collectionGroupBy,
        hideEmptyGroups: visible,
      };
    }

    updateViewFormat(viewId, newGroupSetting);
  };

  const switchColumnColor = (visible: boolean) => {
    if (isLocked) return;

    updateViewFormat(viewId, {
      boardGroupBy: {
        ...groupBy,
        enableBoardColorColumns: visible,
      },
    });
  };

  const groupSchemaType = getGroupSchemaType(collectionId, groupBy?.property);

  let sortName: string | undefined;
  if (groupSchemaType === 'date' || groupSchemaType === 'number' || groupSchemaType === 'text') {
    const sorts = GroupSortMap[groupSchemaType];
    const sort = sorts.find((item) => item.type === groupBy?.sort);
    if (sort) {
      sortName = sort.name;
    } else {
      sortName = sorts.find((item) => item.type === GroupSortType.ASCENDING)?.name;
    }
  }

  let groupLevelName: string | undefined;
  if (groupSchemaType === 'number') {
    const { start, end } = getNumberGroupLevel(groupBy);
    groupLevelName = `${start} 到 ${end}`;
  } else if (
    groupSchemaType === 'date' ||
    (groupSchemaType === 'text' && groupSchema?.type !== CollectionSchemaType.RELATION)
  ) {
    const groupLevels = GroupLevelMap[groupSchemaType];
    const groupLevel = groupLevels.find((item) => item.type === groupBy?.groupLevel);

    if (groupLevel) {
      groupLevelName = groupLevel.name;
    } else {
      const defaultGroupLevel = groupSchemaType === 'date' ? GroupLevel.RELATIVE : GroupLevel.EXACT;
      groupLevelName = groupLevels.find((item) => item.type === defaultGroupLevel)?.name;
    }
  }

  let groupPropertyName = '无';
  if (getValidPropertyIds(collectionId).includes(groupBy?.property ?? '') && groupSchema?.name) {
    groupPropertyName = groupSchema.name;
  }

  const settingList = [
    { name: '分组条件', onClick: selectGroupProperty, value: groupPropertyName },
    { name: '分组方式', onClick: selectGroupLevel, value: groupLevelName },
    { name: '排序', onClick: selectGroupSort, value: sortName },
  ];

  const switchList = [
    {
      name: '隐藏无记录分组',
      onSwitch: switchHiddenEmptyGroup,
      value: hideEmptyGroups,
      visible: !!groupBy?.property,
    },
    {
      name: '分组颜色',
      onSwitch: switchColumnColor,
      value: enableBoardColor,
      visible:
        isBoard &&
        !isSubGroup &&
        (groupSchema?.type === CollectionSchemaType.SELECT ||
          groupSchema?.type === CollectionSchemaType.MULTI_SELECT),
    },
  ];

  return (
    <div className="px-4">
      {settingList.map(({ name, onClick, value }) => {
        if (!value) return null;

        return (
          <div className="flex h-10 items-center justify-between" key={name}>
            <span className="text-t2 flex-shrink-0">{name}</span>

            <div
              className={cx(
                'ml-2 flex cursor-pointer items-center text-ellipsis',
                isLocked && 'cursor-not-allowed'
              )}
              onClick={onClick}
            >
              <span className="text-t2 mr-2 text-ellipsis text-grey3">{value}</span>
              <Icon name="IcArrowDown01" size="xxxsmall" />
            </div>
          </div>
        );
      })}

      {switchList.map(({ name, onSwitch, value, visible }) => {
        if (!visible) return null;
        return (
          <div className="flex h-10 w-full items-center justify-between" key={name}>
            <span className="text-t2">{name}</span>
            <Switch open={value} onSwitch={onSwitch} disabled={isLocked} />
          </div>
        );
      })}
    </div>
  );
};

const SelectGroupProperty: FC<Props> = ({ isSubGroup }) => {
  const { viewId, viewType, collectionId } = useBitable();
  const updateGroupProperty = useUpdateGroupProperty();
  const schemas = useObservableBlock(collectionId, (collection) => collection?.data.schema);
  const groupBy = useGroupBy(viewId, isSubGroup);
  const [groupProperty, setGroupProperty] = useState(groupBy?.property);
  const validPropertyIds = useObservableStore(
    (state) => getValidPropertyIds(collectionId, state),
    [collectionId]
  );

  if (!schemas) return null;

  const items: ListItem<any>[] = [];
  validPropertyIds.forEach((propertyId) => {
    const schema = schemas[propertyId];
    if (!schema) return;
    if (schema.type === CollectionSchemaType.ID_NUMBER) return;
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: schemas[propertyId]?.name,
        propertyId,
        icon: <Icon name={ICON_MAP[schema.type]} size="middle" />,
        hasArrow: groupProperty === propertyId,
        arrow: <Icon name="IcCheck02" size="normal" />,
      },
    });
  });

  if (viewType !== CollectionViewType.BOARD || isSubGroup) {
    items.unshift({
      type: ListItemType.OPERATION,
      data: {
        title: '无',
        hasArrow: typeof groupProperty === 'undefined',
        arrow: <Icon name="IcCheck02" size="normal" />,
      },
    });
  }

  return (
    <ListView
      className="next-modal-scroll w-[160px] py-[5px]"
      items={items}
      onItemClick={(item) => {
        const { propertyId } = item.data;

        if (propertyId !== groupProperty) {
          setGroupProperty(propertyId);
          updateGroupProperty(viewId, propertyId, isSubGroup);
        }
      }}
    />
  );
};

const SelectGroupSort: FC<Props> = ({ isSubGroup }) => {
  const { viewId, viewType, collectionId } = useBitable();
  const groupBy = useGroupBy(viewId, isSubGroup);

  const isBoard = viewType === CollectionViewType.BOARD;
  const groupSchemaType = getGroupSchemaType(collectionId, groupBy?.property);
  if (groupSchemaType !== 'date' && groupSchemaType !== 'number' && groupSchemaType !== 'text') {
    return null;
  }

  const sorts = GroupSortMap[groupSchemaType];
  const sortType = getGroupSort(groupSchemaType, groupBy?.sort);

  const items = sorts.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        sort: item.type,
        arrow: <Icon name="IcCheck02" size="normal" />,
        hasArrow: sortType === item.type,
      },
    };
  });

  return (
    <ListView
      className="next-modal w-[160px] py-[5px]"
      items={items}
      onItemClick={(item) => {
        const { sort } = item.data;

        if (groupBy?.sort !== sort) {
          updateViewFormat(viewId, {
            [isBoard && !isSubGroup ? 'boardGroupBy' : 'collectionGroupBy']: {
              ...groupBy,
              sort,
            },
          });
        }
      }}
    />
  );
};

const SelectGroupLevel: FC<Props> = ({ isSubGroup }) => {
  const { viewId, viewType, collectionId } = useBitable();
  const groupBy = useGroupBy(viewId, isSubGroup);

  const groupSchemaType = getGroupSchemaType(collectionId, groupBy?.property);
  if (groupSchemaType !== 'date' && groupSchemaType !== 'text') return null;

  const groupLevels = GroupLevelMap[groupSchemaType];
  const groupLevel = getGroupLevel(groupSchemaType, groupBy?.groupLevel);

  const items = groupLevels.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        groupLevel: item.type,
        arrow: <Icon name="IcCheck02" size="normal" />,
        hasArrow: groupLevel === item.type,
      },
    };
  });

  return (
    <ListView
      className="next-modal w-[160px] py-[5px]"
      items={items}
      onItemClick={(item) => {
        const { groupLevel } = item.data;

        const isBoardColumn = viewType === CollectionViewType.BOARD && !isSubGroup;
        if (groupBy?.groupLevel !== groupLevel) {
          updateViewFormat(viewId, {
            [isBoardColumn ? 'boardGroupBy' : 'collectionGroupBy']: {
              ...groupBy,
              groupLevel,
            },
            [isBoardColumn ? 'boardGroups' : 'collectionGroups']: [],
          });
        }
      }}
    />
  );
};

const NumberGroupLevel: FC<Props> = ({ isSubGroup }) => {
  const { viewId, viewType } = useBitable();

  const groupBy = useGroupBy(viewId, isSubGroup);

  const { run: updateField } = useDebounceFn((value, field) => {
    const isBoardColumn = viewType === CollectionViewType.BOARD && !isSubGroup;
    updateViewFormat(viewId, {
      [isBoardColumn ? 'boardGroupBy' : 'collectionGroupBy']: {
        ...groupBy,
        [field]: parseFloat(value) || 1,
      },
      [isBoardColumn ? 'boardGroups' : 'collectionGroups']: [],
    });
  });

  const { start, end, size } = getNumberGroupLevel(groupBy);

  return (
    <div className="next-modal w-[300px] py-1.5">
      <div className="text-t2 flex h-10 items-center pl-4 text-grey3">分组范围</div>
      <div className="flex h-10 items-center justify-between px-4 py-2">
        <Input
          className="h-8 w-[120px]"
          style={{ backgroundColor: 'transparent' }}
          onChange={(value) => updateField(value, 'start')}
          defaultValue={start}
          inputType="number"
        />
        <Input
          className="h-8 w-[120px]"
          style={{ backgroundColor: 'transparent' }}
          onChange={(value) => updateField(value, 'end')}
          defaultValue={end}
          inputType="number"
        />
      </div>
      <div className="text-t2 flex h-10 items-center pl-4 text-grey3">每个分组间隔</div>
      <div className="flex h-10 items-center px-4 py-2">
        <Input
          style={{ backgroundColor: 'transparent' }}
          className={` h-8 w-full`}
          onChange={(value) => updateField(value, 'size')}
          defaultValue={size}
          inputType="number"
        />
      </div>
      <div className="text-t2 mx-4 flex items-center text-grey3">
        {start}到{`${BigNumber.sum(start, size)}`}, {`${BigNumber.sum(start, size)}`}到
        {`${BigNumber.sum(start, size * 2)}`}, ...
      </div>
    </div>
  );
};
