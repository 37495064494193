import type { NextBlock } from 'src/redux/types';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from './core/type';

const renderers = new Map<NextBlock['type'], BlockElement>();

const UnSupported: BlockElement = ({ id }) => {
  const block = usePickBlock(id, []);

  // if (block?.type === BlockType.TABLE_ROW) {
  //   return null;
  // }

  // if (block?.type === BlockType.PDF_ANNOTATION) {
  //   return null;
  // }

  if (__HOST_PRODUCTION__) return null;

  return (
    <div className="text-grey4 p-2">
      未支持的块类型 <sup>[{block?.type}]</sup>
    </div>
  );
};

export const _setRenderer = (type: NextBlock['type'], Element: BlockElement) => {
  renderers.set(type, Element);
};

export const getBlockRenderer = (type?: NextBlock['type']) => {
  if (type == null) return UnSupported;
  return renderers.get(type) ?? UnSupported;
};
