import { cx } from '@flowus/common/cx';
import type {
  CollectionFilter,
  CollectionFilterGroup,
  CollectionSchema,
  CollectionViewDTO,
} from '@next-space/fe-api-idl';
import { AggregationAction, CollectionSchemaType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import * as _ from 'lodash-es';
import { css } from 'otion';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Select } from 'src/common/components/select';
import { SelectView } from 'src/common/components/select-view';
import { SortableList } from 'src/common/components/sortable-list';
import { Switch } from 'src/common/components/switch';
import { DATE_FORMAT, SHORT_DATE_FORMAT } from 'src/common/const';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { DateTimePicker } from 'src/editor/editor/plugin/date-picker';
import { DateTimePickerExt } from 'src/editor/editor/plugin/date-picker/date-time-picker-ext';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { getState } from 'src/redux/store';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { ICON_MAP } from '../const';
import { useBitable } from '../context';
import {
  changeFilterField,
  fixGet,
  getAvailableOperators,
  isFilterEnabled,
  isFilterValid,
  pruneFilter,
  RELATIVE_DATE_RANGES,
} from '../table-view/body/filters';
import { PersonInput } from '../table-view/cell/person';
import { TagItem } from '../table-view/cell/select/tag-item';
import { isDateAggregation, isShowOriginalValue } from '../table-view/footer/helper';
import type { Option } from '../table-view/types';
import { ViewIconMap } from './const';
import { SelectRecord } from './record-select';
import type { SettingProps } from './type';
import { useCreateFilter } from '../hooks/use-create-filter';
import { useUpdateFilter } from '../hooks/use-update-filter';

export const ConfigTableFilter: FC<SettingProps> = ({ isFromViewSetting }) => {
  const { viewId, collectionId, isLocked, managerReadonly } = useBitable();
  const view = useCollectionView(viewId);
  const createFilterFun = useCreateFilter();

  const schema = useObservableBlock(collectionId, (collection) => collection?.data.schema ?? {});

  const filter = useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return view?.format.filter;
    },
    [viewId]
  );
  const openModal = useOpenModal();
  const nextCreateProperty = Object.keys(schema)[0];

  if (!view) return null;

  const createFilter = (options?: { group?: boolean }) => {
    if (isLocked) return;
    if (!nextCreateProperty) return;

    bizTracker.event('bitable_view_filter_manage', { manage_type: 'create' });
    createFilterFun(
      {
        collectionId,
        viewId,
      },
      nextCreateProperty,
      managerReadonly,
      options?.group
    );
  };

  const handleCreateFilter = () => {
    createFilter();
  };

  const handleCreateFilterGroup = () => {
    createFilter({ group: true });
  };

  const changeOperator = (operator: 'and' | 'or') => {
    const newFilter: CollectionViewDTO['format']['filter'] = _.cloneDeep(view.format.filter) ?? {
      type: 'group',
      filters: [],
      operator: 'and',
    };
    newFilter.operator = operator;
    updateViewFormat(viewId, { filter: newFilter }, managerReadonly);
  };

  const disableAllFiltersOrNot = (disable: boolean) => {
    if (view.format.filter) {
      updateViewFormat(viewId, { filter: { ...view.format.filter, disable } }, managerReadonly);
    }
  };
  const disableAllSorters = () => {
    disableAllFiltersOrNot(true);
  };
  const enableAllSorters = () => {
    disableAllFiltersOrNot(false);
  };

  const enabled = isFilterEnabled(filter, schema);

  const hasFilterCondition =
    filter && filter.filters.filter((filter) => isFilterValid(filter, schema)).length > 0;

  return (
    <div className={cx('min-w-[480px]', !isFromViewSetting && 'next-modal-scroll')}>
      {hasFilterCondition && (
        <div className="flex justify-between px-4 my-2">
          <div className="flex items-center text-t2">
            <Icon className="mr-1" name={ViewIconMap[view.type]} size="middle" />
            <span className="mr-2 font-medium text-t2">
              筛选符合下方
              <MatchModeSelect
                className="inline-flex font-medium !border-0"
                value={filter.operator}
                onChange={(value) => {
                  changeOperator(value as 'and' | 'or');
                }}
              />
              条件的数据
            </span>
          </div>
          <div className="flex items-center text-t2">
            <span className="mr-1">
              {enabled ? (
                <span className="mr-1 text-active_color">已生效</span>
              ) : (
                <span className="mr-1">未生效</span>
              )}
            </span>
            <Switch
              open={enabled}
              disabled={isLocked}
              onSwitch={() => {
                if (isLocked) return;
                if (enabled) {
                  bizTracker.event('bitable_view_filter_manage', { manage_type: 'off' });
                  disableAllSorters();
                } else {
                  bizTracker.event('bitable_view_filter_manage', { manage_type: 'on' });
                  enableAllSorters();
                }
              }}
            />
          </div>
        </div>
      )}

      {filter && <FilterGroup path={[]} {...filter} />}
      {/* TOOD: Add tooltip when disabled */}

      <button
        className={cx(
          'flex items-center w-full h-10 px-4 py-2 animate-hover !rounded-t-none text-grey3',
          hasFilterCondition && 'border-t border-grey6',
          isLocked && 'cursor-not-allowed'
        )}
        disabled={!nextCreateProperty}
        onClick={handleCreateFilter}
      >
        <Icon className="mr-1" name="IcAdd" size="middle" />
        <span className="text-t2">创建筛选条件</span>
        {!isLocked && (
          <span
            className="inline-flex p-1 animate-hover"
            onClick={(event) => {
              event.stopPropagation();
              openModal.dropdown({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                popcorn: event.currentTarget.parentElement!,
                content: CreateFilterMenu,
                contentProps: {
                  onCreateFilter: handleCreateFilter,
                  onCreateFilterGroup: handleCreateFilterGroup,
                },
                placement: 'bottom-start',
                offset: [-1, 0],
              });
            }}
          >
            <Icon name="IcArrowDown01" className="text-grey3" size="tiny" />
          </span>
        )}
      </button>
    </div>
  );
};

const CreateFilterMenu: FC<{
  onCreateFilter?: () => void;
  onCreateFilterGroup?: () => void;
  onCloseModal: () => void;
}> = ({ onCreateFilter, onCreateFilterGroup, onCloseModal }) => {
  return (
    <div className="next-modal p-2">
      <div
        className="h-9 px-2 flex items-center animate-hover"
        onClick={() => {
          onCreateFilter?.();
          onCloseModal();
        }}
      >
        <Icon name="IcBtnNew" className="mr-2" size="middle" />
        <span className="text-t2">创建筛选条件</span>
      </div>
      <div
        className="h-9 px-2 flex items-center animate-hover"
        onClick={() => {
          onCreateFilterGroup?.();
          onCloseModal();
        }}
      >
        <Icon name="IcAddFilterGroup" className="mr-2" size="middle" />
        <span className="text-t2">创建筛选条件组</span>
      </div>
    </div>
  );
};

const FilterGroup: FC<CollectionFilterGroup & { path: (string | number)[] }> = ({
  path,
  operator,
  filters: filters0,
}) => {
  const { viewId, collectionId, isLocked, managerReadonly } = useBitable();
  const schema = useObservableBlock(collectionId, (block) => block?.data.schema ?? {});
  const openModal = useOpenModal();
  const updateFilterFun = useUpdateFilter();

  const filters = getFilterConditions(filters0, schema);

  const nextCreateProperty = Object.keys(schema)[0];
  const updateFilter = (options?: { group?: boolean }) => {
    if (isLocked) return;
    if (!nextCreateProperty) return;

    bizTracker.event('bitable_view_filter_manage', { manage_type: 'create' });
    updateFilterFun(
      {
        collectionId,
        viewId,
      },
      nextCreateProperty,
      managerReadonly,
      path,
      options?.group
    );
  };

  const handleCreateFilter = () => {
    updateFilter();
  };

  const handleCreateFilterGroup = () => {
    updateFilter({ group: true });
  };

  const removeFilter = (index: number) => {
    const view = getState().collectionViews?.[viewId];
    if (!view) return;

    const newFilter = _.cloneDeep(view.format.filter);
    const group = fixGet(newFilter, path) as CollectionFilterGroup | undefined;
    if (group) {
      group.filters.splice(index, 1);
    }
    if (newFilter) {
      updateViewFormat(viewId, { filter: pruneFilter(newFilter, schema) }, managerReadonly);
    }
  };

  const changeOperator = (operator: 'and' | 'or') => {
    const view = getState().collectionViews?.[viewId];
    if (!view) return;

    const newFilter = _.cloneDeep(view.format.filter);
    const filter = fixGet(newFilter, path);
    filter.operator = operator;

    updateViewFormat(viewId, { filter: newFilter }, managerReadonly);
  };

  const content = (
    <SortableList
      className="flex-1 min-w-0"
      items={filters}
      onlyHandleDraggable
      renderItemContent={({ item }) => {
        const itemPath = path.slice(0);
        itemPath.push('filters', item.id);
        if (item.type === 'group') {
          return (
            <div className="flex items-start my-2">
              <FilterGroup {...item} path={itemPath} />
              <div className="w-9">
                {!isLocked && (
                  <Icon
                    size="large"
                    className="ml-3 mt-1 cursor-pointer text-grey3 hover:text-grey1"
                    name="IcToastClose"
                    onClick={() => {
                      const index = Number(item.id);
                      removeFilter(index);
                      bizTracker.event('bitable_view_filter_manage', { manage_type: 'delete' });
                    }}
                  />
                )}
              </div>
            </div>
          );
        }

        const propertySchema = schema[item.property];
        const { type: schemaType, aggregation } = propertySchema ?? {};

        const avaiableOperators = getAvailableOperators(collectionId, item.property);
        const isEmptyOperator = item.operator === 'isEmpty' || item.operator === 'isNotEmpty';
        const showOperandInput =
          avaiableOperators.map((it) => it.value).includes(item.operator) && !isEmptyOperator;

        const isShowOriginValue =
          schemaType === CollectionSchemaType.ROLLUP && isShowOriginalValue(aggregation);

        return (
          <div className="flex items-center my-2 text-t2">
            <div className="flex items-center flex-1 min-w-0">
              <PropertySelect
                className="w-[120px]"
                value={item.property}
                onChange={(property) => {
                  const index = Number(item.id);
                  changeFilterField(
                    {
                      viewId,
                      collectionId,
                      path,
                      managerReadonly,
                    },
                    index,
                    'property',
                    property
                  );
                }}
              />
              {isShowOriginValue && (
                <RollupMatchModeSelect
                  className="ml-2 w-[72px]"
                  property={item.property}
                  value={item.mode}
                  isShowUniqueValue={aggregation === AggregationAction.SHOW_UNIQUE}
                  onChange={(operator) => {
                    const index = Number(item.id);
                    changeFilterField(
                      {
                        viewId,
                        collectionId,
                        path,
                        managerReadonly,
                      },
                      index,
                      'mode',
                      operator
                    );
                  }}
                />
              )}
              <OperatorSelect
                className={cx('w-[76px]', showOperandInput ? 'mx-2' : 'ml-2')}
                property={item.property}
                value={item.operator}
                onChange={(operator) => {
                  const index = Number(item.id);
                  changeFilterField(
                    {
                      viewId,
                      collectionId,
                      path,
                      managerReadonly,
                    },
                    index,
                    'operator',
                    operator
                  );
                }}
              />

              {showOperandInput && (
                <OperandValue
                  className="w-[160px]"
                  property={item.property}
                  operator={item.operator}
                  value={item.value}
                  path={itemPath}
                  onChange={(value) => {
                    const index = Number(item.id);
                    changeFilterField(
                      {
                        viewId,
                        collectionId,
                        path,
                        managerReadonly,
                      },
                      index,
                      'value',
                      value
                    );
                  }}
                  onEffectiveChange={(value) => {
                    const index = Number(item.id);
                    changeFilterField(
                      {
                        viewId,
                        collectionId,
                        path,
                        managerReadonly,
                      },
                      index,
                      '__effectiveValue',
                      value
                    );
                  }}
                />
              )}
            </div>
            {!isLocked && (
              <Icon
                size="large"
                className="ml-3 cursor-pointer text-grey3 hover:text-grey1"
                name="IcToastClose"
                onClick={() => {
                  const index = Number(item.id);
                  removeFilter(index);
                  bizTracker.event('bitable_view_filter_manage', { manage_type: 'delete' });
                }}
              />
            )}
          </div>
        );
      }}
    />
  );

  const nestedLevel = Math.ceil(path.length / 2);
  if (nestedLevel === 0) {
    if (filters.length === 0) return null;
    return <div className="px-4 mb-3.5">{content}</div>;
  }

  return (
    <div
      className={cx(
        'flex-1 px-3.5 pt-1.5 rounded-md border border-grey5',
        css({ backgroundColor: 'rgba(0, 0, 0, 0.02)' })
      )}
    >
      <div>
        <span className="mr-2 font-medium text-t2">
          筛选符合下方
          <MatchModeSelect
            className="inline-flex font-medium !border-0"
            value={operator}
            onChange={(value) => {
              changeOperator(value as 'and' | 'or');
            }}
          />
          条件的数据
        </span>
      </div>
      {content}
      <div
        className={cx(
          'mt-1 mx-[-14px] h-9 px-3 flex items-center animate-hover text-grey3',
          isLocked && 'cursor-not-allowed'
        )}
        onClick={handleCreateFilter}
      >
        <Icon className="mr-1" name="IcAdd" size="middle" />
        <span className="text-t2">创建筛选条件</span>
        {nestedLevel < 2 && !isLocked && (
          <span
            className="inline-flex p-1 animate-hover"
            onClick={(event) => {
              event.stopPropagation();
              openModal.dropdown({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                popcorn: event.currentTarget.parentElement!,
                content: CreateFilterMenu,
                contentProps: {
                  onCreateFilter: handleCreateFilter,
                  onCreateFilterGroup: handleCreateFilterGroup,
                },
                placement: 'bottom-start',
                offset: [-1, 0],
              });
            }}
          >
            <Icon name="IcArrowDown01" className="text-grey3" size="tiny" />
          </span>
        )}
      </div>
    </div>
  );
};

const PropertySelect: FC<{
  className?: string;
  value: string;
  onChange?: (value: string) => void;
}> = ({ className, value, onChange }) => {
  const { collectionId, isLocked } = useBitable();
  const schema = useObservableBlock(collectionId, (block) => {
    return block?.data.schema ?? {};
  });
  return (
    <Select
      readonly={isLocked}
      className={className}
      dropdownClassName="max-w-[260px]"
      value={value}
      onChange={onChange}
      options={Object.keys(schema)
        .filter((key) => {
          return schema[key]?.type !== CollectionSchemaType.ID_NUMBER;
        })
        .map((key) => {
          const type = schema[key]?.type;
          return {
            value: key,
            title: schema[key]?.name ?? '',
            icon: type && ICON_MAP[type],
          };
        })}
    />
  );
};

const MatchModeSelect: FC<{
  className?: string;
  value: string;
  onChange?: (value: string) => void;
}> = ({ className, value, onChange }) => {
  const { isLocked } = useBitable();

  return (
    <Select
      readonly={isLocked}
      className={className}
      value={value}
      onChange={onChange}
      options={[
        { value: 'and', title: '全部' },
        { value: 'or', title: '任意' },
      ]}
    />
  );
};

const RollupMatchModeSelect: FC<{
  className?: string;
  property: string;
  value?: string;
  isShowUniqueValue?: boolean;
  onChange?: (value: string) => void;
}> = ({ isShowUniqueValue, className, value, onChange }) => {
  const { isLocked } = useBitable();
  const options = isShowUniqueValue
    ? [{ value: 'any', title: '任意' }]
    : [
        { value: 'any', title: '任意' },
        { value: 'every', title: '全部' },
        { value: 'none', title: '无' },
      ];
  return (
    <Select
      readonly={isLocked}
      className={className}
      value={isShowUniqueValue ? 'any' : value}
      onChange={onChange}
      options={options}
    />
  );
};

export const OperatorSelect: FC<{
  className?: string;
  property: string;
  value: string;
  onChange?: (value: string) => void;
}> = ({ className, property, value, onChange }) => {
  const { collectionId, isLocked } = useBitable();
  const avaiableOperators = getAvailableOperators(collectionId, property);
  return (
    <Select
      readonly={isLocked}
      className={className}
      value={value}
      onChange={onChange}
      options={avaiableOperators}
    />
  );
};

// @ts-ignore TODO yuxiang
export const OperandValue: FC<{
  className?: string;
  operator: string;
  property: string;
  value: string;
  path: (string | number)[];
  onChange: (value: string) => void;
  onEffectiveChange?: (value: string) => void;
}> = ({
  className: className0,
  property,
  operator,
  value,
  path,
  onChange: onChange0,
  onEffectiveChange,
}) => {
  const { collectionId, isLocked } = useBitable();
  const { propertySchema, relationPropertySchema, targetPropertySchema } = usePropertySchema(
    collectionId,
    property
  );
  const { type, aggregation, targetProperty = '' } = propertySchema ?? {};
  const relationCollectionId = relationPropertySchema?.collectionId ?? '';
  const formulaTool = getFormulaTool(collectionId);
  const relationFormulaTool = getFormulaTool(relationCollectionId);
  const avaiableOperators = getAvailableOperators(collectionId, property);
  const isEmptyOperator = operator === 'isEmpty' || operator === 'isNotEmpty';
  const showOperandInput =
    avaiableOperators.map((it) => it.value as string).includes(operator) && !isEmptyOperator;

  const debounceTimer = useRef<number | undefined>();

  useEffect(() => {
    return () => {
      clearTimeout(debounceTimer.current);
    };
  }, []);

  const onChange = (value: string) => {
    onChange0(value);
    clearTimeout(debounceTimer.current);
    debounceTimer.current = window.setTimeout(() => {
      onEffectiveChange?.(value);
    }, 500);
  };

  let schemaType = type;
  const isRollup = schemaType === CollectionSchemaType.ROLLUP;
  const isFormula = schemaType === CollectionSchemaType.FORMULA;
  if (isRollup) {
    const targetPropertyType = targetPropertySchema?.type;
    const isShowOriginValue = isShowOriginalValue(aggregation);
    if (isShowOriginValue) {
      if (targetPropertyType === CollectionSchemaType.FORMULA) {
        schemaType = relationFormulaTool.getTypeAsCollectionSchemaType(targetProperty);
      } else {
        schemaType = targetPropertyType;
      }
    } else if (isDateAggregation(aggregation)) {
      schemaType = CollectionSchemaType.DATE;
    } else {
      schemaType = CollectionSchemaType.NUMBER;
    }
  } else if (isFormula) {
    schemaType = formulaTool.getTypeAsCollectionSchemaType(property);
  }

  const className = cx(className0, 'h-8');
  if (!showOperandInput) return null;

  // eslint-disable-next-line default-case
  switch (schemaType ?? CollectionSchemaType.TEXT) {
    case CollectionSchemaType.TITLE: // fallthrough
    case CollectionSchemaType.TEXT:
    case CollectionSchemaType.NUMBER:
    case CollectionSchemaType.URL:
    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.PHONE:
      return (
        <Input
          readonly={isLocked}
          className={cx(className, 'border-grey5')}
          value={value}
          onChange={onChange}
        />
      );
    case CollectionSchemaType.CREATED_AT:
    case CollectionSchemaType.UPDATED_AT:
    case CollectionSchemaType.DATE: {
      if (operator === 'in') {
        return <DateRangeInput className={className} value={value} onChange={onChange} />;
      }
      return <DateInput className={className} value={value} onChange={onChange} />;
    }
    case CollectionSchemaType.SELECT:
    case CollectionSchemaType.MULTI_SELECT:
      return (
        <TagInput className={className} property={property} value={value} onChange={onChange} />
      );
    // 勾选框不需要 OperandValue
    case CollectionSchemaType.CHECKBOX:
    case CollectionSchemaType.FILE:
      return null;

    case CollectionSchemaType.PERSON:
    case CollectionSchemaType.CREATED_BY:
    case CollectionSchemaType.UPDATED_BY:
      return <PersonInput className={className} value={value} onChange={onChange} />;
    case CollectionSchemaType.RELATION:
      return (
        <RecordInput
          propertyId={property}
          className={className}
          value={value}
          path={path}
          onChange={onChange}
        />
      );
  }
};

export const RecordInput: FC<{
  propertyId: string;
  className?: string;
  value?: string;
  path: (string | number)[];
  onChange?: (value: string) => void;
}> = ({ className, value, path, onChange, propertyId }) => {
  const { isLocked, collectionId } = useBitable();
  const { propertySchema, targetPropertySchema } = usePropertySchema(collectionId, propertyId);
  const reallyCollectionId =
    propertySchema?.type === CollectionSchemaType.RELATION
      ? propertySchema.collectionId ?? ''
      : targetPropertySchema?.collectionId ?? '';

  const subNodes = getState().blocks[reallyCollectionId ?? '']?.subNodes ?? [];
  const content = (
    <div className="text-t4">
      {value?.split(',').map((uuid) => {
        if (uuid && subNodes.includes(uuid)) {
          return <InlinePage uuid={uuid} key={uuid} />;
        }
        return null;
      })}
    </div>
  );

  return (
    <SelectView
      readonly={isLocked}
      className={className}
      value={value}
      content={content}
      onChange={onChange}
      Dropdown={SelectRecord}
      dropdownProps={{
        propertyId,
        path,
      }}
    />
  );
};

const formatDateRangeFilterValue = (value: string) => {
  if (!value) return { title: '', shortTitle: undefined };
  const startEnd = value.split(',');
  const start = dayjs(startEnd[0], DATE_FORMAT);
  const end = dayjs(startEnd[1], DATE_FORMAT);
  return {
    title: `${start.format(DATE_FORMAT)} → ${end.format(DATE_FORMAT)}`,
    shortTitle: `${start.format(SHORT_DATE_FORMAT)} → ${end.format(SHORT_DATE_FORMAT)}`,
  };
};

const validateDateFilterValue = (value: string, type: 'range' | 'day') => {
  if (type === 'range') {
    const r = RELATIVE_DATE_RANGES[value];
    if (r != null) {
      return !r.day;
    }
    if (!value) return false;
    const startEnd = value.split(',');
    return dayjs(startEnd[0], DATE_FORMAT).isValid() && dayjs(startEnd[1], DATE_FORMAT).isValid();
  }

  const r = RELATIVE_DATE_RANGES[value];
  if (r != null) {
    return r.day;
  }
  return dayjs(value, DATE_FORMAT).isValid();
};

export const DateRangeInput: FC<{
  className?: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ className, value, onChange }) => {
  const { isLocked } = useBitable();
  const normValue = validateDateFilterValue(value, 'range') ? value : '';
  const { title, shortTitle } = formatDateRangeFilterValue(normValue);
  const relValue = _.has(RELATIVE_DATE_RANGES, normValue) ? normValue : 'exact';
  return (
    <>
      <Select
        readonly={isLocked}
        value={relValue}
        className="mr-2"
        options={[
          { title: '具体时间', value: 'exact' },
          ..._.entries(RELATIVE_DATE_RANGES)
            .filter((it) => !it[1].day)
            .map(([key, it]) => {
              return {
                title: it.title,
                value: key,
              };
            }),
        ]}
        onChange={(value) => {
          if (value === 'exact') {
            onChange('');
          } else {
            onChange(value);
          }
        }}
      />
      {relValue === 'exact' && (
        <SelectView
          readonly={isLocked}
          className={className}
          value={normValue}
          title={title}
          shortTitle={shortTitle}
          onChange={(value) => {
            onChange(value);
          }}
          Dropdown={DateRangeInputDropdown}
          dropdownProps={{}}
        />
      )}
    </>
  );
};

export const DateInput: FC<{
  className?: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ className, value, onChange }) => {
  const { isLocked } = useBitable();
  const normValue = validateDateFilterValue(value, 'day') ? value : '';
  const relValue = _.has(RELATIVE_DATE_RANGES, normValue) ? normValue : 'exact';
  return (
    <>
      <Select
        readonly={isLocked}
        value={relValue}
        className="mr-2"
        options={[
          { title: '具体时间', value: 'exact' },
          ..._.entries(RELATIVE_DATE_RANGES)
            .filter((it) => it[1].day)
            .map(([key, it]) => {
              return {
                title: it.title,
                value: key,
              };
            }),
        ]}
        onChange={(value) => {
          if (value === 'exact') {
            onChange('');
          } else {
            onChange(value);
          }
        }}
      />
      {relValue === 'exact' && (
        <SelectView
          readonly={isLocked}
          className={className}
          value={normValue}
          title={normValue}
          onChange={onChange}
          Dropdown={DateInputDropdown}
          dropdownProps={{}}
        />
      )}
    </>
  );
};

const DateRangeInputDropdown: FC<{
  value?: string;
  onChange?: (value: string) => void;
  onCloseModal: () => void;
}> = ({ value, onChange, onCloseModal }) => {
  const startEnd = value ? value.split(',') : [];
  const start = dayjs(startEnd[0], DATE_FORMAT);
  const end = dayjs(startEnd[1], DATE_FORMAT);
  return (
    <DateTimePickerExt
      includeTime={false}
      showTimeSwitch={false}
      closeDatePicker={onCloseModal}
      date={{
        from: start.isValid() ? start.toDate() : new Date(),
        to: end.isValid() ? end.toDate() : new Date(),
      }}
      onChange={({ from, to }) => {
        onChange?.(`${dayjs(from).format(DATE_FORMAT)},${dayjs(to).format(DATE_FORMAT)}`);
      }}
      onClear={() => {
        onChange?.('');
      }}
    />
  );
};

const DateInputDropdown: FC<{
  value?: string;
  onChange?: (value: string) => void;
  onCloseModal: () => void;
}> = ({ value, onChange, onCloseModal }) => {
  const date = dayjs(value, DATE_FORMAT);
  return (
    <DateTimePicker
      uiOption={{
        hasSwitchTimeOption: false,
        hasFormatSetting: false,
      }}
      closeDatePicker={onCloseModal}
      dateInfo={{ from: date.isValid() ? date.toDate() : new Date(), includeTime: false }}
      onChange={({ from }) => {
        onChange?.(dayjs(from).format(DATE_FORMAT));
      }}
      onClear={() => {
        onChange?.('');
      }}
    />
  );
};

const TagInput: FC<{
  className?: string;
  property: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ className, property, value, onChange }) => {
  const { collectionId, isLocked } = useBitable();
  const options = useObservableStore(
    ({ blocks }) => {
      const schema = blocks[collectionId]?.data.schema;
      const propertySchema = schema?.[property];
      if (!schema) return [];

      if (propertySchema?.type === CollectionSchemaType.ROLLUP) {
        const { targetProperty = '', relationProperty = '' } = propertySchema;
        const relationCollectionId = schema[relationProperty]?.collectionId;
        return blocks[relationCollectionId ?? '']?.data.schema?.[targetProperty]?.options ?? [];
      }

      return propertySchema?.options ?? [];
    },
    [collectionId]
  );

  const option = options.find((it) => it.id === value);
  return (
    <SelectView
      readonly={isLocked}
      className={className}
      value={value}
      content={option && <TagItem color={option.color} value={option.value} />}
      onChange={onChange}
      Dropdown={TagInputDropdown}
      dropdownProps={{
        options,
      }}
    />
  );
};

const TagInputDropdown: FC<{
  options: Option[];
  onCloseModal: () => void;
  onChange?: (value: string) => void;
}> = ({ options, onCloseModal, onChange }) => {
  const [value, setSearch] = useState('');

  const items = options
    .filter((item) => item.value.includes(value))
    .map((it) => {
      return {
        type: ListItemType.OPERATION,
        data: {
          content: <TagItem color={it.color} value={it.value} />,
          onClick: () => {
            onChange?.(it.id);
            onCloseModal();
          },
        },
      };
    });

  return (
    <div className="max-h-[80vh] w-[190px] overflow-y-auto next-modal py-2">
      <ListView
        customHeader={
          <Input
            autoFocus
            className="h-8 mx-2.5 mb-2.5"
            placeholder="搜索选项"
            onChange={(value) => setSearch(value)}
          />
        }
        items={items}
      />
    </div>
  );
};

export const preprocessFilter = (
  schema: Record<string, CollectionSchema>,
  it: CollectionFilter | CollectionFilterGroup
) => {
  if (it.type !== 'filter') return it;
  if (it.propertyType && schema[it.property]?.type === it.propertyType) return it;
  if (['isEmpty', 'isNotEmpty'].includes(it.operator)) return it;
  return { ...it, value: '' };
};

export const getFilterConditions = (
  filters: (CollectionFilterGroup | CollectionFilter)[],
  schema: Record<string, CollectionSchema>
) => {
  return filters
    .map((it, index) => ({ ...it, id: `${index}` }))
    .filter((it) => {
      // 属性已不存在
      if (!isFilterValid(it, schema)) return false;
      return true;
    })
    .map((filter) => preprocessFilter(schema, filter)) as ((
    | CollectionFilter
    | CollectionFilterGroup
  ) & { id: string })[];
};
