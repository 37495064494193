import { message } from '@flowus/common/components/message';
import { VITE_PREFIX } from '@flowus/common/const';
import type { ModalSchema } from '@flowus/common/next-modal';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { VITE_NOTION_CLIENT_ID } from 'src/env';
import { ViewPath } from 'src/utils';
import { sequence } from 'src/utils/async-utils';
import { enqueueTask } from 'src/utils/tasks';
import { TocType } from 'src/views/main/aside/toc/types';
import { useCommon } from './hook';

export const ImportNotion: FC<ModalSchema.CloseModal> = () => {
  const {
    createDocPage,
    fetchPage,
    currentSpace,
    closeAllModal,
    history,
    openLoading,
    closeLoading,
  } = useCommon();

  const [id, setId] = useState('');

  const onClick = () => {
    const width = 800;
    const height = 800;

    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const redirectUri = encodeURIComponent(`${VITE_PREFIX}${ViewPath.notion}`);

    window.open(
      `https://api.notion.com/v1/oauth/authorize?client_id=${VITE_NOTION_CLIENT_ID}&response_type=code&owner=user&redirect_uri=${redirectUri}`,
      'notion',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const onSubmit = () => {
    if (id.length !== 32) {
      message.error('Notion id 错误, 如果是 database 只需要 ?v= 之前的部分');
      return;
    }

    const pageId = createDocPage(BlockType.PAGE, { parentId: currentSpace.uuid }, TocType.PRIVATE);

    if (!pageId) return;

    openLoading('正在导入，可以先干干其他事');

    void sequence(() =>
      enqueueTask(
        {
          eventName: 'import',
          request: {
            blockId: pageId,
            spaceId: currentSpace.uuid,
            importOptions: {
              type: 'notion',
              notionId: id,
            },
          },
        },
        {
          success: async ({ uuid }) => {
            uuid && void fetchPage(uuid, { force: true });
            closeLoading();
            closeAllModal();
            history.push(`/${pageId}`);
          },
          fail() {
            if (pageId) {
              closeLoading();
              message.warning(
                'Had a bit of a problem getting the task status, import is still in progress'
              );
            }
          },
        }
      )
    );
  };

  return (
    <div className="w-[600px] p-8 flex justify-center space-x-8">
      <div
        className="flex items-center justify-center flex-col space-y-4 border rounded p-4 shadow animate-hover animate-click"
        onClick={onClick}
      >
        <div className="text-t2">第一步</div>
        <div className="text-t1">授权待导入 Notion 页面</div>
        <div className="text-t4 text-grey2">已授权页面可以跳过此步骤</div>
      </div>
      <div className="flex-1 flex items-center justify-center flex-col space-y-4 p-4">
        <div className="text-t2">第二步</div>
        <Input
          className="w-full"
          inputClassName="h-8"
          placeholder="输入已授权 Notion 页面 id"
          onChange={setId}
          value={id}
        />
        <Button disable={!id} onClick={onSubmit}>
          开始导入
        </Button>
      </div>
    </div>
  );
};
