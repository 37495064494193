import type { SegmentDTO } from '@next-space/fe-api-idl';
import { newSelection } from '@next-space/fe-inlined';
import type { RefObject } from 'react';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { getState } from 'src/redux/store';
import type { SegmentType } from 'src/redux/types';
import { parseEditorKeyToBlockId } from 'src/utils/editor-key-to-id';
import { focusTracker } from './focus-tracker';
import {
  SYNCED_SEGMENTS_REF_SYMBOL,
  syncSegmentsToEditorModel,
} from './sync-segments-to-editor-model';

export const focusEditableAtByEditorKey = (
  editorKey: string | undefined,
  at: number,
  type?: SegmentType
) => {
  if (!editorKey) {
    return;
  }
  const id = parseEditorKeyToBlockId(editorKey);

  focusTracker.activeEditorKey = editorKey;

  const block = getState().blocks[id];
  if (!block) {
    return;
  }
  const time = Date.now();
  let alreadyCall = false;
  const callback = () => {
    const editorModel = getEditorModelByEditorKey(editorKey);
    if (editorModel === undefined) {
      if (alreadyCall) {
        return;
      }
      if (Date.now() - time <= 100) {
        alreadyCall = true;
        setTimeout(callback);
      }
      return;
    }

    const block = getState().blocks[id];
    if (!block) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const syncedSegmentsRef = (editorModel as any)[SYNCED_SEGMENTS_REF_SYMBOL] as RefObject<
      SegmentDTO[] | undefined
    >;
    if (!syncedSegmentsRef) {
      return;
    }
    if (at === -1) {
      // focus at last cursor
      at = editorModel.content.length;
    }
    const segments = type === 'caption' ? block.data.caption : block.data?.segments;
    if (syncedSegmentsRef.current === segments) {
      editorModel.performChange((ctx) => {
        ctx.select(at);
      });
    } else {
      // 这个可能导致图片的caption清空
      // syncSegmentsToEditorModel(editorModel, segments, newSelection(at, at));
    }
    void editorModel.requestFocus();
    void editorModel.scrollCaretIntoViewIfNeeded();
  };

  void Promise.resolve().then(callback);
};
