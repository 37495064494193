import { Compare } from '@flowus/common';
import { CodePreviewFormat } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { codeLanguageCanView } from '@flowus/common/mermaid/helper';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { Select } from 'src/common/components/select';
import { LabelSelectView } from 'src/common/components/select-view';
import { useVisible } from 'src/common/hooks/use-visible';
import { ResizeElement } from 'src/editor/component/resize-element';
import { useResize } from 'src/hooks/block/use-resize';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useReadonly } from 'src/hooks/page';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { useExpand } from 'src/redux/managers/ui/use-fold';
import { $searchParams } from 'src/utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { judgeSharePage } from 'src/utils/getPageId';
import { setLocalStorage } from 'src/utils/local-storage';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { Caption } from '../../../component/caption';
import { segmentsToText, textToSegments } from '../../../utils/editor';
import type { BlockElement } from '../../core/type';
import { BlockDrop } from '../dnd/block-drop';
import { CodeEditor } from './code-editor';
import { CodePreviewSelect, MermaidElement } from './code-mermaid';
import { modeWhitelist } from './codemirror-utils';
import { DEFAULT_CODE_LANGUAGE, LAST_SELECTED_CODE_LANGUAGE } from './const';

const MIN_HEIGHT = 40;

export const CodeBlockContent: BlockElement = ({ id, root }) => {
  const saveSegments = useThrottleUpdateSegments(id);
  const transaction = useTransaction();
  const isSharePage = judgeSharePage();
  const block = usePickBlock(
    id,
    ['textColor', 'data'],
    ['segments', 'format', 'description', 'viewMode']
  );
  const readonly = useReadonly(id);
  const scene = usePageScene();
  const [expand = true, setExpand] = useExpand(id);
  const [isEditorTitle, setIsEditorTitle] = useState(false);
  const {
    renderSize,
    onRenderSize,
    changeSize,
    containerWidth,
    containerRef,
    isDisable,
    defaultHeight,
  } = useResize({
    id,
    root,
    defaultHeight: block?.data.format?.height,
    fullPageContentWidth: true,
    resizeWidth: false,
  });
  const [isAutoHeight, setIsAutoHeight] = useState(!defaultHeight);
  const language = block?.data.format?.language ?? DEFAULT_CODE_LANGUAGE;
  const wordWrap = block?.data.format?.codeWrap ?? true;
  const codePreviewView = block?.data.format?.codePreviewFormat ?? CodePreviewFormat.splitView;
  const canView = codeLanguageCanView(language, codePreviewView);
  const { allowSelectionCopy } = usePermissions(id);
  const enableCopy = !(!allowSelectionCopy && isSharePage);

  const changeWordWrap = useCallback(() => {
    transaction(() => {
      const format = block?.data.format ?? {};
      updateBlock(id, { data: { format: { ...format, codeWrap: !wordWrap } } });
    });
  }, [block?.data.format, id, transaction, wordWrap]);

  const handleChangeDescription = useCallback(
    (value: string) => {
      if (!block?.uuid) return;
      transaction(() => {
        updateBlock(block.uuid, {
          updatedAt: Date.now(),
          data: {
            description: textToSegments(value),
          },
        });
      });
    },
    [block?.uuid, transaction]
  );

  const isExpand = $searchParams.print || expand;
  const description = segmentsToText(block?.data.description);

  if (!block) return null;

  return (
    <div className="w-full h-full" ref={containerRef}>
      <div className="rounded bg-grey8">
        <div className="h-10 flex items-center text-t2 justify-between px-2 overflow-x-auto">
          <div className="flex items-center text-ellipsis flex-shrink-0 text-black">
            <Icon
              name="IcToggleGrey"
              size="middle"
              className={cx(
                'text-grey3 mr-1 animate-hover transition-transform rotate-90',
                !isExpand && 'rotate-0'
              )}
              onClick={() => setExpand(!isExpand)}
            />
            {!isEditorTitle ? (
              <div
                className={cx(
                  'animate-hover text-ellipsis w-full px-0.5 min-w-[30px] h-[22px] group-hover:opacity-100 transition-opacity',
                  !description && 'text-grey4 opacity-0'
                )}
                onClick={(e) => {
                  if (readonly) return;
                  e.preventDefault();
                  e.stopPropagation();
                  setIsEditorTitle(true);
                }}
              >
                {description ? description : readonly ? '' : '请输入代码块名称'}
              </div>
            ) : (
              <Input
                autoFocus
                showBorder={false}
                readonly={readonly}
                className="h-7 w-full rounded-sm"
                inputClassName="px-0.5 text-t2 h-7"
                placeholder="请输入代码块名称"
                defaultValue={description}
                onBlur={() => {
                  if (isEditorTitle) {
                    setIsEditorTitle(false);
                  }
                }}
                onChange={handleChangeDescription}
              />
            )}
          </div>
          <div className="transition-opacity opacity-0 group-hover:opacity-100 flex items-center flex-shrink-0 text-t2 text-grey3 ml-2 h-7">
            <LanguageSelect
              className={'h-full'}
              value={language}
              readonly={readonly}
              onChange={(language) => {
                transaction(() => {
                  setLocalStorage(LAST_SELECTED_CODE_LANGUAGE, language);
                  const format = block.data.format ?? {};
                  updateBlock(id, { data: { format: { ...format, language } } });
                });
              }}
            />
            {!readonly && (
              <>
                {canView.button && <CodePreviewSelect blockId={id} />}
                <div className={'h-4 w-px bg-grey4 mx-2.5'} />
                <div
                  className={'flex items-center px-1 animate-hover h-full'}
                  onClick={changeWordWrap}
                >
                  <Icon name="IcCode" size="middle" className="mr-1" />
                  {'自动换行'}&nbsp;{wordWrap ? '开启' : '关闭'}
                </div>
                <div className={'h-4 w-px bg-grey4 mx-2.5'} />
              </>
            )}
            <div
              hidden={!enableCopy}
              className={cx(
                'h-7 px-1 animate-hover flex items-center',
                scene === PageScene.NOTIFICATION && 'hidden'
              )}
              onClick={() => {
                void writeTextInClipboard(segmentsToText(block.data.segments), {
                  message: '已复制代码',
                });
              }}
            >
              <Icon size="middle" className="mr-1" name="IcCopy" />
              <span>复制</span>
            </div>
          </div>
        </div>
        {isExpand && (
          <>
            {canView.code && (
              <ResizeElement
                resizeHeight
                readonly={readonly || isDisable}
                defaultBlockFullWidth
                resizeWidth={false}
                maxWidth={containerWidth}
                minHeight={MIN_HEIGHT}
                defaultWidth={containerWidth}
                defaultHeight={defaultHeight || renderSize.height || MIN_HEIGHT}
                onChange={changeSize}
                onRenderSize={onRenderSize}
                onKeyDownMoveBar={() => {
                  setIsAutoHeight(false);
                }}
                onKeyUpMoveBar={() => {
                  setIsAutoHeight(!defaultHeight);
                }}
                className={cx(
                  'flex relative z-0 w-full group border-t',
                  (isDisable || !enableCopy) && 'pointer-events-none',
                  isAutoHeight && '!h-full'
                )}
              >
                <div className={cx('h-full', !isAutoHeight && 'overflow-y-auto')}>
                  <div className={cx(!isAutoHeight && 'h-full')}>
                    <CodeEditor
                      className={cx('pb-4', !isAutoHeight && 'h-full')}
                      id={id}
                      noScrollbars={scene === PageScene.NOTIFICATION}
                      maxHeight={scene === PageScene.NOTIFICATION ? 150 : undefined}
                      padding={'0px'}
                      language={language}
                      wordWrap={wordWrap}
                      segments={block.data.segments}
                      onChange={(segments, prevSnapshot) => {
                        saveSegments(segments, prevSnapshot);
                      }}
                    />
                  </div>
                </div>
              </ResizeElement>
            )}
            {canView.preview && <MermaidElement blockId={id} />}
          </>
        )}
      </div>
      <Caption blockId={id} className="px-2" />
    </div>
  );
};

const CodeBlockEditor: BlockElement = (props) => {
  const { id, root } = props;
  const isDarkMode = useIsDarkMode();

  return (
    <BlockDrop
      key={`${id}-darkMode-${isDarkMode}`}
      id={id}
      className="my-1 group"
      horizontal={root}
    >
      <CodeBlockContent {...props} />
    </BlockDrop>
  );
};

export const CodeBlockElement: BlockElement = (props) => {
  const block = usePickBlock(props.id, ['data'], ['description', 'segments', 'format']);
  const [visible, ref] = useVisible<HTMLDivElement>();
  const [expand = true] = useExpand(props.id);

  if (visible || $searchParams?.print || !expand) {
    return <CodeBlockEditor {...props} />;
  }

  const description = segmentsToText(block?.data.description);
  const text = segmentsToText(block?.data.segments ?? []);
  const height = block?.data.format?.height;

  return (
    <div ref={ref} className="bg-grey8 my-1 text-t2" data-block-id={props.id}>
      <div className="px-3 h-8 flex items-center">{description}</div>
      <div
        className="p-3 pl-10 whitespace-pre-wrap break-words border-t border-grey6"
        style={{
          height: height ? height : 'unset',
        }}
      >
        {text}
      </div>
    </div>
  );
};

const LanguageSelect: FC<{
  className?: string;
  value: string;
  readonly?: boolean;
  onChange: (value: string) => void;
}> = ({ className, value, onChange, readonly }) => {
  const options = modeWhitelist.map((name) => {
    return {
      title: name,
      value: name,
    };
  });
  options.sort(Compare.by((it) => it.title, Compare.string));
  return (
    <Select
      SelectView={LabelSelectView}
      enableSearch
      readonly={readonly}
      className={className}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
