import { assign } from 'lodash-es';
import { commonInjector, isBuildIn } from './build-in';
import { isFlowUsApp } from './utils/ds-bridge';
export { KB, MB, GB, TB } from '@flowus/shared/unit';

export const {
  VITE_CONTACT_ADMIN_LINK,
  VITE_PUBLIC_LINK,
  VITE_URL_SPLIT_TEXT,
  VITE_PREFIX,
  VITE_WECHAT_ACCOUNT,
} =
  // @ts-ignore env
  import.meta.env;

/**  '[BuildIn_AI]' : '【FlowUs 息流】'*/
export const getUrlSplitText = () => {
  try {
    return VITE_URL_SPLIT_TEXT;
  } catch {
    return ' ';
  }
};

export const URL_SPLIT_TEXT = getUrlSplitText();

/** 视图方式 */
export enum ViewMode {
  normal = 'normal',
  card = 'card',
  preview = 'preview',
}

export enum CodePreviewFormat {
  splitView = 'splitView',
  code = 'code',
  preview = 'preview',
}

export enum PageFontFamily {
  lxgwWenKai = '"LXGW WenKai", serif',
  sourceHanSerif = 'source-han-serif-sc-regular, SimSun, serif',
  lora = 'b-lora',
  DMMono = 'b-DMMono',
}

export const LOCAL_LNG = {
  isEmpty: commonInjector.isBuildIn ? 'Is empty' : '未填写',
  countEmpty: commonInjector.isBuildIn ? 'Count empty' : '未填写',
  countNotEmpty: commonInjector.isBuildIn ? 'Count not empty' : '已填写',
  isNotEmpty: commonInjector.isBuildIn ? 'Is not empty' : '已填写',
  embedWebPage: commonInjector.isBuildIn ? 'Create embed' : '嵌入网页',
  fixedWidth: commonInjector.isBuildIn ? 'Fixed width' : '固定宽度',
  fixed: commonInjector.isBuildIn ? 'Fixed' : '固定宽度',
  pageFont: commonInjector.isBuildIn ? 'Page font' : '默认字体',
  linkToPage: commonInjector.isBuildIn ? 'Link to page' : '引用页面',
  yearly: commonInjector.isBuildIn ? 'Yearly' : '年',
  monthly: commonInjector.isBuildIn ? 'Monthly' : '月',
};

export const BuildInEmail = 'team@buildin.ai';
export const FlowUsEmail = 'cm@allflow.cn';

export const BACKUP_DOMAIN = ['allflow.space'];

export const getCurrentDomain = (defaultDomain: string) =>
  BACKUP_DOMAIN.includes(location.hostname) ? location.hostname : defaultDomain;

export const CURRENT_DOMAIN = getCurrentDomain(VITE_PREFIX ?? '');

export const getPublicLink = (prefix = getCurrentDomain(CURRENT_DOMAIN)) => {
  /** 全局共用的一些死链接 */
  const flowusPublicLink = {
    /** 用户协议 */
    userAgreement: `${prefix}/share/51171168-3fcb-4b14-b359-53a58380da4c`,
    /** 隐私条款 */
    privacyPolicy: `${prefix}/share/29651bee-1323-4f0a-a9ba-535832469fe0`,
    /** 官网 */
    product: `${prefix}/product`,
    /** 官网价格 */
    pricing: `${prefix}/pricing`,
    /** app download */
    appDownload: `${prefix}/download`,
    /** 帮助中心 */
    helpCenter: `${prefix}/share/e68380be-23cf-42c4-9b95-23a52713213d`,
    /** 快捷键 */
    shortcut: `${prefix}/share/c19d16ae-56f0-4e29-bc20-f46302eeebfc`,
    /** 快捷命令 */
    shortcutCommand: `${prefix}/share/401e6d4a-ded8-4e23-a874-a1c30a5928d7`,
    /** 付费条款 */
    paidServices: `${prefix}/share/734ccb8d-760d-4792-8c89-2d644dbe24d9`,
    // 积分规则
    integralRule: `${prefix}/share/c7e03855-8742-4453-abea-ab0625209955`,
    /** 帮助中心 - 分享功能介绍 */
    helperShare: `${prefix}/share/478fbe3f-8074-48ac-9357-6f0af02b894b`,
    /** 问题反馈 */
    problemFeedback: `${prefix}/form/a5ed5933-cf92-4c65-9bb1-d2ea974a5aaa`,
    /** 更新日志 */
    updateLog: `${prefix}/share/b30dd458-7fb9-4e29-b5ed-e09274465c34`,
    /** 申请学校 */
    applySchool: `${prefix}/form/42f08dfb-dc06-4582-bb8e-8385bdc6a3af`,
    /** 活动认证 */
    authentication: `${prefix}/auth/school`,
    /** 申请开发票 */
    applyInvoice: `${prefix}/form/0fd2831b-2793-443a-95c3-2a753c0ecef0a`,
    /** 了解 FlowUs 息流 */
    learnMore: `${prefix}/share/19cc8990-ac51-43c9-9144-36cb7c09f9c9`,
    /** 微信剪藏 */
    weChatClipper: `${prefix}/share/74fbd0eb-1255-484d-b9a1-a0bafd6be4c6`,
    /** 自动续费地址 */
    cyclePlan: `${prefix}/share/7803ae51-aa1d-4d28-a8d6-6bbdbf42ab49`,
    /** 批量导入 */
    importGuest: `${prefix}/share/8172adf1-bbe4-4d1f-99b9-8bbda920d5d9`,
    /** 视频教学*/
    videoTeaching: `${prefix}/share/5b6ba2f7-f332-4d9b-a283-c0443b683646`,
    /** 引用建议 */
    quoteSuggestion: `${prefix}/share/34d1188d-04b2-4502-a732-ae3b6ec69fb1`,
    /** 引用页面 */
    quotePage: `${prefix}/share/c15b6489-60ba-480e-a84b-ad28f9a3f694`,
    /** 模板投稿 */
    templateContribution: `${prefix}/share/4ac3f00d-02db-4b24-aaed-48a19ceff78f`,
    /** 校园达人 */
    campusMaster: `${prefix}/share/fe31151c-da91-4cab-9ec5-610df0ca480a`,
    /** 从notion导入 */
    importFromNotion: `${prefix}/share/7d5a2460-c632-401b-9880-a672565047e0#9ed2ef68-7345-4fb8-af9a-d1bc49f5df77`,
    /** 如何导入 */
    howToImport: `${prefix}/share/7d5a2460-c632-401b-9880-a672565047e0`,
    /** 模板需求反馈 */
    templateFeedback: `${prefix}/share/185ba39c-721a-4088-8dda-7c6815e72cdb`,
    ai: `${prefix}/ai`,
    aiTerms: `${prefix}/share/55698213-8c16-4710-a147-2279853aa133`,
    // ai: `${prefix}/form/149f529b-8e31-464d-909d-6d708df7242b`,
    // newActivity: `${prefix}/share/0937bbbe-d75b-41a5-9780-175b8b511713`,
    dataPanel: `${prefix}/share/1af3d985-f43f-4ca0-b575-87ac8d1983e6`,
    shareDataPanel: `${prefix}/share/478fbe3f-8074-48ac-9357-6f0af02b894b`,
    collaborateInfo: `${prefix}/share/a84fff54-ca15-4516-8959-e1b50890dde0`,
    /**申请专栏(订阅) */
    recommendSubscribe: `${prefix}/form/1b02734c-3a67-4dca-8e0b-284df5df6159`,
    /** 了解空间权益 buildin */
    // spacePlanInfo: `${prefix}/share/a84fff54-ca15-4516-8959-e1b50890dde0`,
    distribution: `${prefix}/distribution`,

    /** 邀请成员协作 */
    invitePage: `${prefix}/share/a84fff54-ca15-4516-8959-e1b50890dde0`,
    /** 公开分享链接 */
    sharePageLink: `${prefix}/share/478fbe3f-8074-48ac-9357-6f0af02b894b`,
    /** 分享表单链接 */
    shareFormLink: `${prefix}/share/6e310b4f-cf88-4a02-8092-1a6a7b0240e0`,
    bitableLink: `${prefix}/share/689e1d3e-04ab-4388-bba4-8e6085b15b26`,
    /** 视图帮助链接 */
    tableViewLink: `${prefix}/share/e3041662-4374-4169-b5a8-60347781b879`,
    boardViewLink: `${prefix}/share/8646a5e1-ba30-4de4-8c40-03842dbf7bb3`,
    galleryViewLink: `${prefix}/share/875d347e-10e6-43c0-bed2-f56764946f2f`,
    listViewLink: `${prefix}/share/d6926b36-62cf-4f65-95d8-5af9550ac6c8`,
    timeViewLink: `${prefix}/share/e2d0c9d4-ca1d-4993-a2ab-ed75ff3cf86c`,
    calendarViewLink: `${prefix}/share/09b243c8-d310-4230-9a8a-882af611b281`,
    formViewLink: `${prefix}/share/3f4e57ad-ecdc-4a48-877a-519484cfaf09`,
    /** 注销账户 */
    cancelAccount: `${prefix}/share/3c4d3d0e-f0d3-4ed3-a70c-ee0e7d44ae7d`,
    /** 如何获得空间升级优惠 */
    upgradeSpace: `${prefix}/share/769d984e-40b0-416b-9046-573439737dd0`,
    /** 共享经济协议 - 云账户 */
    yzh: `${prefix}/share/0eb789dc-9634-4e91-84f8-2ff76b22b2e0`,
    /** 创作者协议 */
    creator: `${prefix}/share/eee6a7e5-e574-4efb-8991-c4aad9398999`,
    /** 分销联盟 */
    rebateRule: `${prefix}/share/889a74e8-8eeb-4db0-8f19-e8254e547bcc`,
    /** 知识库 ｜ 订阅中心 */
    subscribeCenter: `${prefix}/subscribecenter`,
    /** 默认订阅中心主页 */
    subScribeDefaultPage: `${prefix}/share/93f01b87-fb24-4153-a021-d0a35b22ad71`,
    /** subscribe Rule */
    subscribeRule: `${prefix}/share/b9074bd9-6681-4ab8-800a-1045dd406234`,
    /** 我的订阅 */
    mySubscribePage: `/subscribe/share`,
    /**快速注册*/
    quickRegister: `/login?quick-register=true`,
  };

  /** buildin 专用 */
  const buildInPublicLink = {
    /** 用户协议 */
    userAgreement: `${prefix}/share/2120db09-65bd-46af-92c8-a13351a6844d`,
    /** 隐私条款 */
    privacyPolicy: `${prefix}/share/4ab3ac80-08b5-4796-a7b6-46db637630d3`,
    /** 帮助中心 */
    helpCenter: `${prefix}/share/ed00d504-90ac-4a3c-9914-2a7edf841d7a`,
    /** 快捷键 */
    shortcut: `${prefix}/share/a96f6a1a-6f9b-463f-ac93-3ac4a642d329`,
    /** 快捷命令 */
    shortcutCommand: `${prefix}/share/4b0388a6-48a9-4328-b183-b7a63a8ff9b8`,
    /** 付费条款 */
    paidServices: `${prefix}/share/2733e0cc-6c6a-423d-9740-296427b702f5`,
    /** 更新日志 */
    updateLog: `${prefix}/share/c7488479-8b1e-4318-9b11-2f6b86378999`,
    /** 描述 */
    learnMore: '/product',
    /** 空间付费条款 */
    marketingProgramParticipantTerms: `${prefix}/share/da6dbbb4-d847-484b-8cc2-4159c57939a2`,
    /** ai 付费条款 */
    aiSupplementaryTerms: `${prefix}/share/b8a645b4-e528-4a8a-b203-2e9c1a124c73`,
    /** 从notion导入 */
    importFromNotion: `${prefix}/share/af6d500a-6115-4fdb-a6ba-75e3588866f1#dfb6eca8-d77c-48bd-96b1-b5d42df38756`,
    howToImport: `${prefix}/share/af6d500a-6115-4fdb-a6ba-75e3588866f1`,
  };

  // isBuildIn 决定谁覆盖谁
  const publicLink = isBuildIn()
    ? { ...flowusPublicLink, ...buildInPublicLink }
    : { ...buildInPublicLink, ...flowusPublicLink };

  if (VITE_PUBLIC_LINK) {
    for (const key in publicLink) {
      assign(publicLink, {
        [key]: VITE_PUBLIC_LINK,
      });
    }
  }

  if (isFlowUsApp.check) {
    for (const key in publicLink) {
      if (!['subscribeCenter', 'mySubscribePage'].includes(key as keyof typeof publicLink)) {
        assign(publicLink, {
          // @ts-ignore key
          [key]: `${publicLink[key]}?out=true`,
        });
      }
    }
  }

  return { publicLink, buildInPublicLink };
};

export const commonPublicLink = getPublicLink().publicLink;

export const MAX_INVITE_CODE_SIZE = 8;
export const UPGRADE_MINI_SPACE_PEOPLE = 1;
export const SMART_TEAM_MAX_PEOPLE = 5;

export const SpacePlanConfig = {
  free: {
    maxGuest: 5,
  },
};
