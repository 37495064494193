import { Role } from '@flowus/common';
import { LOCAL_LNG } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { debounce } from 'lodash-es';
import type { MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useOpenMoveTo } from 'src/components/move-to';
import { useOpenCompleteDelete } from 'src/components/open-complete-delete';
import { UpdateUserInfo } from 'src/components/update-user-info';
import { createMenuDataContext } from 'src/editor/component/menu-list/menu-item';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useBlockStatus } from 'src/hooks/block/use-block-status';
import { useMultipleDownload } from 'src/hooks/drive/use-multiple-download';
import { useReadonly } from 'src/hooks/page';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { useCanMove, useIsGuest, useParentIsOk } from 'src/hooks/share/use-permission-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useGetShareHeaderMenuItems } from 'src/hooks/share/use-share-header-menu-list';
import { getCurrentSpace } from 'src/hooks/space';
import { useIsCustomTemplate } from 'src/hooks/space/use-is-custom-template';
import { useSpaceSecurityState } from 'src/hooks/space/use-space-security-state';
import { useTemplatesLimit } from 'src/hooks/space/use-templates-limit';
import { useTransaction } from 'src/hooks/use-transaction';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { Modals } from 'src/modals';
import { HISTORY_REDO, HISTORY_UNDO } from 'src/redux/actions';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { updateBlock } from 'src/redux/managers/block/update';
import * as TemplateManager from 'src/redux/managers/template';
import { dispatch, getState } from 'src/redux/store';
import { useSharedPages } from 'src/services/app';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import { isMindMap } from 'src/utils/block-type-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { CommentTabIndex, setCommentListIndex } from 'src/views/comments/page-resolved-discussions';
import { leaveDoc } from '../aside/toc/more-bottom';
import { PageFeedTabIndex, setPageFeedIndex } from '../drawer/page-feeds/page-feeds';
import { useSetDrawerOpenState } from '../drawer/shared';
import { OpenSettingFrom, SettingMenuType } from '../setting-modal/type';
import { useOpenSettingModal, useOpenUpgradeSpace } from '../setting-modal/use-open-setting-modal';
import { CommentSettingList } from './comment-setting-list';
import { ExportFile } from './export-file';
import { ExportPanel } from './export-file/export-panel';
import { LayoutSwitcher } from './layout-switcher';
import { PageHistoryModal } from './page-history';

export const MenuButton = () => {
  const uuid = useGetPageId();
  const openModal = useOpenModal();
  const isShared = judgeSharePage();
  const readonly = useReadonly(uuid);
  const pageBlock = usePickBlock(uuid, []);
  const parentIsOk = useParentIsOk(uuid);
  const isSharePage = judgeSharePage();

  const openMenuList = (event: MouseEvent<HTMLElement>) => {
    if (disabled) return;

    bizTracker.event('page_menu_click', {
      page_type: getState().blocks[uuid]?.type,
      is_share: isShared,
    });
    const popcorn = event.currentTarget;
    openModal.dropdown({
      modalId: Modals.PAGE_HEADER_MENU_LIST,
      popcorn,
      placement: 'bottom-end',
      offset: [0, 15],
      content() {
        return <MenuList popcorn={popcorn} uuid={uuid} />;
      },
    });
  };

  const IS_NOT_PAGE = pageBlock?.type !== BlockType.PAGE;
  // 分享页的菜单按钮不置灰; 多维表还需要根据父级判断（因为父级不行的话 菜单列表里都空了）
  const disabled = !isShared && (readonly || (IS_NOT_PAGE && !parentIsOk));

  if (isSharePage && __PRIVATE__) return null;

  return (
    <Tooltip
      popup="菜单"
      id="page-header-more"
      {...bindDataTestId(TestIds.pageHeaderMore)}
      disabled={disabled}
      onClick={openMenuList}
      className={cx(
        'p-1 flex items-center justify-center',
        disabled ? 'cursor-not-allowed' : 'animate-hover'
      )}
    >
      <Icon size="middle" name="IcMore" className={cx(disabled && 'opacity-20')} />
    </Tooltip>
  );
};

const MenuList = (props: { popcorn: HTMLElement; uuid: string }) => {
  const { popcorn, uuid } = props;
  const isInRight = useIsInRight();
  const multipleDownload = useMultipleDownload();
  const isDeleted = useBlockStatus(uuid);
  const pageBlock = usePickBlock(
    uuid,
    ['data', 'updatedAt', 'updatedBy'],
    ['pageFixedWidth', 'directoryMenu', 'format', 'isByAI']
  );
  const userName = useUserName(pageBlock?.updatedBy ?? '');
  // 默认打开固定页面宽度
  const { pageFixedWidth = true, directoryMenu } = pageBlock?.data || {};
  const openMoveTo = useOpenMoveTo();
  const transaction = useTransaction();
  const { selectedBlockHistory } = useSelectedBlockHistory();
  const parentIsOk = useParentIsOk(uuid);
  const locked = useBlockLocked(uuid);
  const canMove = useCanMove(uuid);
  const menuItems = useGetShareHeaderMenuItems(uuid);
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const openCompleteDelete = useOpenCompleteDelete(uuid);
  const sharedPages = useSharedPages();
  const isGuest = useIsGuest();
  const isTemplate = useIsCustomTemplate(uuid);
  const limit = useTemplatesLimit();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const updateTask = useUpdateTask();
  const setDrawerOpenState = useSetDrawerOpenState();
  const openSettingModal = useOpenSettingModal();
  const commentAlignment = pageBlock?.data.format?.commentAlignment ?? 'top';
  const abandonedExport = useSpaceSecurityState('abandonedExport');

  // 被风控了
  const { illegal, role } = usePermissions(uuid);
  const editorUser = [PermissionRole.WRITER, PermissionRole.EDITOR].includes(role);

  const isSharePage = judgeSharePage();
  const IS_NOT_PAGE = pageBlock?.type !== BlockType.PAGE;
  const isCollection =
    pageBlock?.type === BlockType.COLLECTION_VIEW ||
    pageBlock?.type === BlockType.COLLECTION_VIEW_PAGE ||
    pageBlock?.type === BlockType.REFERENCE_COLLECTION ||
    pageBlock?.type === BlockType.REFERENCE_COLLECTION_PAGE;
  const isFolder = pageBlock?.type === BlockType.FOLDER;
  const location = useLocation(); // 右侧panel的拷贝链接需要这个

  let items: ListItem[] = menuItems;
  if (!isSharePage) {
    if (illegal) {
      items = [
        // 如果是外部协作者，且 uuid 在 sharedPages 里认为是顶层
        isGuest && sharedPages.includes(uuid)
          ? {
              type: ListItemType.OPERATION,
              data: {
                icon: 'MIcSettingsLogout',
                title: '退出协作',
                onClick: () => {
                  void leaveDoc(uuid);
                },
              },
            }
          : {
              type: ListItemType.OPERATION,
              data: {
                icon: 'IcTrash',
                title: '彻底删除',
                onClick: () => openCompleteDelete(),
              },
            },
        {
          type: ListItemType.LINE,
          isHidden: !editorUser,
          data: {},
          disableClick: true,
        },
      ];
    } else {
      items = [
        {
          type: ListItemType.TEXT_SWITCH,
          isHidden: IS_NOT_PAGE,
          data: {
            title: LOCAL_LNG.fixedWidth,
            switch: pageFixedWidth,
            onSwitch: (status: boolean) => {
              bizTracker.event('fixedwidth', { is_open: status });
              transaction(() => {
                updateBlock(uuid, { data: { pageFixedWidth: status } });
              });
            },
          },
        },
        {
          type: ListItemType.TEXT_SWITCH,
          isHidden: IS_NOT_PAGE,
          data: {
            title: '页面目录',
            switch: directoryMenu,
            onSwitch: (status: boolean) => {
              bizTracker.event('float_catalog_switch', { is_open: status });
              transaction(() => {
                // 积分任务
                void updateTask(ActivityIds.CREATE_HEADER_BLOCK, ActivitiesListType.basicList, {
                  step2: true,
                });
                updateBlock(uuid, { data: { directoryMenu: status } });
              });
              // 多个用户反馈每个页面都需要手动打开，好烦。
              // 如果系统没有设置默认打开，并且用户在一个session中打开超过3次，我们有必要提示一下用户可以在设置中打开这个选项
              if (!status) return;
              const defaultOpen = $currentUserCache.setting?.directoryMenu;
              if (defaultOpen) return;
              const settingTipCount = parseInt(
                sessionStorage.getItem('settingTipCount') ?? '0',
                10
              );
              sessionStorage.setItem('settingTipCount', (settingTipCount + 1).toString());
              if (settingTipCount === 2) {
                openModal.warning({
                  title: '是否希望新建页面的目录自动打开?',
                  content: '【设置】->【通用设置】->【新建页面目录】',
                  cancelText: '取消',
                  colorType: 'active',
                  confirmText: '现在就去设置',
                  confirm: () => {
                    openSettingModal({
                      defaultMenuType: SettingMenuType.general,
                      from: OpenSettingFrom.tip,
                    });
                  },
                });
              }
            },
          },
        },
        {
          type: ListItemType.LINE,
          isHidden: IS_NOT_PAGE || !parentIsOk,
          data: {},
          disableClick: true,
        },
        {
          type: ListItemType.CUSTOM_RENDER,
          isHidden: isMindMap(pageBlock?.type),
          render: () => {
            return (
              <LayoutSwitcher
                className="px-4 my-3"
                pageId={uuid}
                hideLayoutComponent={isCollection || isFolder}
              />
            );
          },
        },
        {
          type: ListItemType.LINE,
          isHidden: isMindMap(pageBlock?.type),
          data: {},
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          isHidden: isMindMap(pageBlock?.type),
          data: {
            icon: 'IcComment',
            title: '页面评论位置',
            hasArrow: true,
            renderSubMenu: () => {
              return (
                <CommentSettingList
                  commentAlignment={commentAlignment}
                  onChangeCommentAlignment={(v) => {
                    transaction(() => {
                      updateBlock(uuid, {
                        data: {
                          format: {
                            commentAlignment: v,
                          },
                        },
                      });
                    });
                  }}
                />
              );
            },
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: IS_NOT_PAGE && !isCollection && !isMindMap(pageBlock?.type),
          data: {
            icon: locked ? 'IcMenuUnlock' : 'IcLock',
            title: locked
              ? isCollection
                ? '解锁多维表'
                : '解锁页面'
              : isCollection
              ? '锁定多维表'
              : '锁定页面',
            tip: isCollection
              ? '锁定时，无法新增/修改多维表的配置、视图和属性，但可以新增/编辑记录。'
              : '锁定时，无法编辑页面',
            onClick: () => {
              bizTracker.event('page_lock', {
                is_open: !locked,
              });
              transaction(() => {
                updateBlock(uuid, {
                  data: {
                    format: {
                      locked: !locked,
                      lockedBy: $currentUserCache.uuid,
                    },
                  },
                });
              });
            },
            rightText: locked ? `${ShortcutSystemSymbol}+L` : `${ShortcutSystemSymbol}+L`,
          },
        },
        {
          type: ListItemType.LINE,
          isHidden: (IS_NOT_PAGE && !isCollection) || isFolder,
          data: {},
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          isHidden: !parentIsOk || role === PermissionRole.READER || role === PermissionRole.NONE,
          data: {
            icon: 'IcLink',
            title: '拷贝页面访问链接',
            onClick: debounce(
              () => {
                bizTracker.event('copy_page_link');
                void writeTextInClipboard(`${getLocationOrigin()}${location.pathname}`);
              },
              300,
              { leading: true }
            ),
          },
        },
        {
          type: ListItemType.LINE,
          isHidden: IS_NOT_PAGE || isDeleted || !parentIsOk,
          data: {},
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          isHidden: role === PermissionRole.READER || role === PermissionRole.NONE,
          data: {
            icon: 'IcUndo',
            rightText: `${ShortcutSystemSymbol}+Z`,
            title: '撤销',
            onClick: debounce(
              () => {
                bizTracker.event('undo_click');
                dispatch(HISTORY_UNDO());
              },
              300,
              { leading: true }
            ),
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: role === PermissionRole.READER || role === PermissionRole.NONE,
          data: {
            icon: 'IcRedo',
            rightText: `${ShortcutSystemSymbol}+Shift+Z`,
            title: '重做',
            onClick: debounce(
              () => {
                bizTracker.event('redo_click');
                dispatch(HISTORY_REDO());
              },
              300,
              { leading: true }
            ),
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: role !== PermissionRole.EDITOR && role !== PermissionRole.WRITER,
          data: {
            icon: 'IcPageHistory',
            title: '页面历史',
            onClick: debounce(
              () => {
                openModal.modal({
                  modalId: Modals.DOC_HISTORY_MODAL_ID,
                  content: () => {
                    return <PageHistoryModal docId={uuid} />;
                  },
                });
              },
              300,
              { leading: true }
            ),
          },
        },
        {
          type: ListItemType.OPERATION,
          // 可评论权限没有已解决评论入口，可编辑才有
          isHidden: !Role.contains(role, PermissionRole.WRITER),
          data: {
            icon: 'IcCommentResolved',
            title: '已解决评论',
            onClick: debounce(
              () => {
                setCommentListIndex(CommentTabIndex.RESOLVE_COMMENT);
                setDrawerOpenState(
                  isInRight ? 'page-resolved-discussions-right' : 'page-resolved-discussions'
                );
              },
              300,
              { leading: true }
            ),
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden:
            isDeleted ||
            !parentIsOk ||
            role === PermissionRole.READER ||
            role === PermissionRole.NONE,
          data: {
            icon: 'IcTrash',
            title: '删除',
            onClick: debounce(
              () => {
                bizTracker.event('delete_pageclick');
                transaction(() => {
                  archiveBlock(uuid);
                });
              },
              300,
              { leading: true }
            ),
          },
        },
        {
          type: ListItemType.LINE,
          data: {},
          disableClick: true,
        },
        {
          type: ListItemType.TEXT_SWITCH,
          isHidden:
            isFolder ||
            isDeleted ||
            isGuest ||
            role === PermissionRole.READER ||
            role === PermissionRole.NONE,
          data: {
            icon: 'IcTemplate',
            title: '转换为模板',
            switch: isTemplate,
            context: createMenuDataContext({
              title: '转换为模板后，有协作权限的空间成员和访客可以在模板库看到并使用模板。',
              image: 'custom_template_tip',
            }),
            onSwitch: (status: boolean) => {
              transaction(() => {
                if (status) {
                  if (!limit) {
                    bizTracker.event('temp_switch', {
                      is_open: true,
                    });
                    TemplateManager.add(uuid, {
                      parentId: getCurrentSpace().uuid,
                      first: true,
                    });
                    updateBlock(uuid, {
                      data: {
                        createdTemplateBy: $currentUserCache.uuid,
                      },
                    });

                    // 新手任务
                    void updateTask(
                      ActivityIds.CONVERT_TO_TEMPLATE,
                      ActivitiesListType.advancedList
                    );

                    return;
                  }
                  openModal.warning({
                    title: (
                      <div className="mb-5">
                        <p className="text-t1-medium mb-2.5">
                          {'已达到空间模板上限，升级空间解锁无限数量的模板。'}
                        </p>
                      </div>
                    ),
                    colorType: 'active',
                    confirmText: '升级空间',
                    confirm: () => {
                      openUpgradeSpace(OpenSettingFrom.temp_max);
                    },
                  });
                } else {
                  bizTracker.event('temp_switch', {
                    is_open: false,
                  });
                  TemplateManager.remove(uuid, {
                    parentId: getCurrentSpace().uuid,
                  });
                }
              });
            },
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: isFolder || __PRIVATE__,
          data: {
            disable: abandonedExport,
            icon: 'IcExportPage',
            title: '导出页面',
            onClick: debounce(
              () => {
                openModal.modal({
                  content: ({ onCloseModal }) => {
                    if (isMindMap(pageBlock?.type)) {
                      return <ExportPanel onCloseModal={onCloseModal} id={uuid} />;
                    }
                    return <ExportFile onCloseModal={onCloseModal} />;
                  },
                });
              },
              300,
              { leading: true }
            ),
          },
        },
        {
          type: ListItemType.LINE,
          isHidden: !canMove || isFolder,
          data: {},
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          isHidden: !isFolder,
          data: {
            icon: 'IcDownload',
            title: '下载',
            onClick: () => {
              void multipleDownload([uuid]);
            },
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: !canMove,
          data: {
            icon: 'IcMove',
            rightText: `${ShortcutSystemSymbol}+Shift+P`,
            title: '移动到',
            onClick: () => {
              bizTracker.event('moveto_pageclick');
              selectedBlockHistory([]);
              openMoveTo({
                popcorn,
                uuid,
                placement: 'bottom-end',
                offset: [0, 15],
              });
            },
          },
        },
        {
          type: ListItemType.LINE,
          isHidden: !(editorUser && !isSharePage),
          data: {},
          disableClick: true,
        },
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcInfo',
            title: '页面信息',
            onClick: () => {
              setPageFeedIndex(PageFeedTabIndex.PAGE_INFO);
              setDrawerOpenState(isInRight ? 'page-feeds-right' : 'page-feeds');
            },
          },
        },
        {
          type: ListItemType.LINE,
          data: {},
          disableClick: true,
        },
      ];
    }
  }

  return (
    <ListView
      className="py-1 rounded-r w-[250px] next-modal max-h-[90vh] overflow-auto"
      items={items}
      customFooter={
        editorUser &&
        !isSharePage && (
          <UpdateUserInfo
            name={userName}
            updatedAt={pageBlock?.updatedAt}
            showWordCount={pageBlock?.type === BlockType.PAGE}
            showMindNodeCount={isMindMap(pageBlock?.type)}
            isByAI={pageBlock?.data.isByAI}
          />
        )
      }
      onItemClick={() => closeModal(Modals.PAGE_HEADER_MENU_LIST)}
    />
  );
};
