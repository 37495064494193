import { canPreViewImage, getFormatImageUrl } from '@flowus/common';
import { LOCAL_LNG } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { FileRegex } from '@flowus/common/regex';
import { fastEqual } from '@flowus/common/utils/tools';
import { fileUploader } from '@flowus/upload';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { Provider as JotaiProvider } from 'jotai';
import * as _ from 'lodash-es';
import { css } from 'otion';
import type { FC, VFC } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { message } from 'src/common/components/message';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { SortableList } from 'src/common/components/sortable-list';
import { Tooltip } from 'src/common/components/tooltip';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { FilePreViewIcon } from 'src/components/file-preview-icon';
import { getPreviewComponent } from 'src/components/file-preview/renderElements';
import { ImagesProvider } from 'src/components/images-provider';
import { newFileSegment } from 'src/editor/utils/editor';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { usePropertySegments } from 'src/hooks/block/use-property-segments';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useReadonly } from 'src/hooks/page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useUpload } from 'src/hooks/space/use-upload';
import { useGetMediaInfo } from 'src/hooks/utils/use-get-media-info';
import { Modals } from 'src/modals';
import { cache, getState } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { combineFullName, getFileNameInfo } from 'src/utils/file';
import { judgeSharePage } from 'src/utils/getPageId';
import { urlFetcher } from 'src/utils/url-fetcher';
import { v4 as uuid } from 'uuid';
import { COLORS } from '../../const';
import { TagItem } from './select/tag-item';
import type { CellEditorProps, CellViewProps } from './types';
import { Site } from './types';
import { $filePreviewModel, FILE_PREVIEW_SCOPE } from 'src/components/file-preview/hook';
import { OfficeEditorBtn } from 'src/components/file-preview/header';

export const ALIYUN_IMGPROCESS_SIZE_LIMIT = 20971520;

interface UploadTask {
  file: File;
  abort: Function;
  progress$: BehaviorSubject<number>;
  recordId: string;
  propertyId: string;
}

const uploadManager = {
  tasks: [] as UploadTask[],
  tasksChange$: new Subject<void>(),
};

export const useAddUploadTask = () => {
  const upload = useUpload();
  const updatePropertyValue = useUpdatePropertyValue();
  const getMediaInfo = useGetMediaInfo();
  return useCallback(
    async (file: File, recordIds: string[], propertyId: string) => {
      if (uploadManager.tasks.find((task) => task.file === file)) {
        return;
      }
      const progress$ = new BehaviorSubject(0);
      const firstRecordId = recordIds[0] ?? '';
      const key = uuid();
      const uploadId = await upload({
        key,
        file,
        isDatabase: true,
        inTransferList: true,
        blockId: recordIds[0],
        onProgress: (progress) => {
          progress$.next(progress);
        },
        type: 'file',
        onComplete: async (ret) => {
          _.remove(uploadManager.tasks, (task) => task.file === file);
          uploadManager.tasksChange$.next();
          if (!ret.success) {
            return;
          }
          progress$.complete();
          const mediaInfo = await getMediaInfo(file);

          const segment = newFileSegment({
            name: file.name,
            size: file.size,
            ossName: ret.ossName,
            ...mediaInfo,
          });

          const { blocks } = getState();
          const segments = blocks[firstRecordId]?.data.collectionProperties?.[propertyId];
          const fileSegments = (segments ?? []).filter((item) => {
            return item.type === TextType.URL && item.fileStorageType === 'internal';
          });
          fileSegments.push(segment);
          updatePropertyValue(recordIds, propertyId, fileSegments);
        },
      });

      // 没有id说明失败了
      if (!uploadId) {
        return;
      }

      recordIds.forEach((uuid) => {
        uploadManager.tasks.push({
          file,
          abort: () => {
            fileUploader.abort(uploadId);
          },
          progress$,
          recordId: uuid,
          propertyId,
        });
      });

      uploadManager.tasksChange$.next();
    },
    [getMediaInfo, updatePropertyValue, upload]
  );
};

const useUploadingTasks = (recordId: string, propertyId: string) => {
  return useObservable(
    () =>
      uploadManager.tasksChange$.pipe(
        map(() =>
          uploadManager.tasks.filter(
            (task) => task.recordId === recordId && task.propertyId === propertyId
          )
        )
      ),
    uploadManager.tasks.filter(
      (task) => task.recordId === recordId && task.propertyId === propertyId
    ),
    [recordId, propertyId]
  );
};

export const FilesValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const segments = usePropertySegments(recordId, propertyId);
  const uploadingTasks = useUploadingTasks(recordId, propertyId);
  const openFile = useOpenFile();

  return (
    <div
      className={cx(
        'flex items-center',
        site === Site.FIELD &&
          css({
            selectors: {
              ':empty:before': {
                content: `'${LOCAL_LNG.isEmpty}'`,
                display: 'block',
                fontSize: '14px',
                padding: '3px',
                color: 'var(--grey4)',
                lineHeight: '20px',
              },
            },
          }),
        (site === Site.CELL || site === Site.FIELD) && 'p-[5px]',
        (site === Site.CELL || site === Site.FIELD || site === Site.CARD) && 'flex-wrap',
        className
      )}
    >
      {(segments ?? [])
        .filter((it) => it.type === TextType.URL && it.fileStorageType === 'internal')
        .map((it, index) => {
          const canPreView = canPreViewImage(it.url);
          const tagItem = (
            <TagItem
              key={index}
              label={it.text}
              color={COLORS[0].key}
              className={cx(
                'align-middle max-w-[calc(100%-6px)]',
                (site === Site.CELL || site === Site.FIELD) && 'm-[3px]',
                (site === Site.CARD || site === Site.LIST) && 'mr-1 my-0.5 text-t4',
                site === Site.CALENDAR && 'mr-1 my-[1px] text-t4',
                canPreView && 'cursor-pointer'
              )}
              onClick={async (event) => {
                if (!canPreView) return;

                event.stopPropagation();
                openFile(recordId, propertyId, it);
              }}
            />
          );

          if (canPreView && (!it.size || it.size <= ALIYUN_IMGPROCESS_SIZE_LIMIT)) {
            const previewImage = FileRegex.image.test(getFileNameInfo(it.text).extName);
            return (
              <FilePreViewIcon
                key={index}
                className={cx(
                  'h-5 w-5 m-[3px] cursor-pointer border border-grey6 rounded-sm overflow-hidden inline-flex',
                  (site === Site.CELL || site === Site.FIELD) && 'm-[3px]',
                  site === Site.CARD || site === Site.LIST ? 'mr-1 my-0.5' : 'm-[3px]',
                  site === Site.CALENDAR && 'mr-1 my-[1px]'
                )}
                uuid={recordId}
                ossName={it.url}
                defaultIcon={tagItem}
                options={{
                  videoPlayIconSize: 'small',
                  resizeWidth: 40,
                  previewImage,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (previewImage) {
                    return;
                  }
                  openFile(recordId, propertyId, it);
                }}
              />
            );
          }
          return tagItem;
        })}
      {uploadingTasks.length > 0 && <LoadingIcon size="large" />}
    </div>
  );
};

const UploadingFile: FC<{
  task: UploadTask;
}> = ({ task }) => {
  const progress = useObservable(() => task.progress$, task.progress$.value, [task]);
  return (
    <div className="flex items-center h-8 py-1 mx-3 cursor-default text-t2">
      <Icon
        size="normal"
        className="text-xl leading-none text-grey4 animate-spin"
        name="IcNaviLoading"
      />
      <span className="flex-1 mx-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
        {task.file.name}
      </span>
      <span className="mx-1">{progress.toFixed(1)}%</span>
      <button
        className="flex items-center justify-center w-5 h-5 ml-1 animate-hover"
        onClick={() => {
          task.abort();
        }}
      >
        <Icon size="small" name="IcTitleClose" />
      </button>
    </div>
  );
};

interface DropdownMenuProps {
  recordIds?: string[];
  recordId: string;
  propertyId: string;
  data: SegmentDTO;
  onCloseModal: () => void;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useOpenFile = () => {
  const openModal = useOpenModal();

  return (recordId: string, propertyId: string, data: SegmentDTO) => {
    if (!data.url) return;

    const { extName } = getFileNameInfo(data.text);
    const isImage = FileRegex.image.test(extName);
    bizTracker.event('file_preview', {
      file_format: extName,
    });
    openModal.modal({
      modalId: Modals.FILE_PREVIEW,
      noAnimation: true,
      content: () =>
        isImage ? (
          <ImageGallery recordId={recordId} propertyId={propertyId} data={data} />
        ) : (
          <PreView recordId={recordId} propertyId={propertyId} data={data} />
        ),
    });
  };
};

const DropdownMenu: VFC<DropdownMenuProps> = ({
  recordIds,
  recordId,
  propertyId,
  data,
  onCloseModal,
  setShowInput,
}) => {
  const readonly = useReadonly(recordId, false);
  const updatePropertyValue = useUpdatePropertyValue();
  const openFile = useOpenFile();
  const { allowDownload } = getPermissions(recordId);

  const downloadRecordFile = async (recordId: string, data: SegmentDTO) => {
    const url = await urlFetcher.fetchDownloadUrl({
      blockId: recordId,
      ossName: data.url ?? '<never>',
      fileName: data.text,
    });
    void downloadUrlFile(url, data.text);
  };

  return (
    <div className="next-modal w-[200px] rounded-sm py-2">
      <ListView
        items={[
          {
            type: ListItemType.OPERATION,
            isHidden: judgeSharePage() && !allowDownload,
            data: {
              title: '下载',
              onClick: () => {
                onCloseModal();

                void downloadRecordFile(recordId, data);
              },
            },
          },
          {
            type: ListItemType.OPERATION,
            data: {
              title: '查看',
              onClick: () => {
                onCloseModal();

                void openFile(recordId, propertyId, data);
              },
            },
          },
          {
            type: ListItemType.OPERATION,
            isHidden: readonly,
            data: {
              title: '重命名',
              onClick: () => {
                onCloseModal();

                setShowInput(true);
              },
            },
          },
          {
            type: ListItemType.OPERATION,
            isHidden: readonly,
            data: {
              title: '删除',
              onClick: () => {
                onCloseModal();
                const { blocks } = getState();
                const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
                updatePropertyValue(
                  recordIds ?? recordId,
                  propertyId,
                  segments?.filter((it) => it !== data)
                );
              },
            },
          },
        ]}
      />
    </div>
  );
};

enum DragStatus {
  NONE = 'none',
  DRAGGING = 'dragging',
  DRAGGING_OVER = 'draggingOver',
}
const DragTip = {
  [DragStatus.NONE]: '点击上传，或粘贴/拖入文件',
  [DragStatus.DRAGGING]: '拖入文件上传',
  [DragStatus.DRAGGING_OVER]: '松手即可上传文件',
};
export const FilesEditor: FC<CellEditorProps> = ({ recordIds, recordId, propertyId }) => {
  const readonly = useReadonly(recordId, false);
  const segments = usePropertySegments(recordId, propertyId);
  const fileSegments = (segments ?? []).filter((item) => {
    return item.type === TextType.URL && item.fileStorageType === 'internal';
  });
  const uploadingTasks = useUploadingTasks(recordId, propertyId);
  const addUploadTask = useAddUploadTask();
  const updatePropertyValue = useUpdatePropertyValue();

  const [status, setStatus] = useState<DragStatus>(DragStatus.NONE);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const inputNode = inputRef.current;
    const inputContainer = inputNode?.parentElement;
    const container = inputContainer?.parentElement;
    if (!container || !inputContainer || !inputNode) return;

    const handleDragEnter = (event: DragEvent) => {
      if (!(event.target instanceof HTMLElement)) return;

      if (inputContainer.contains(event.target)) {
        setStatus(DragStatus.DRAGGING_OVER);
      } else {
        setStatus(DragStatus.DRAGGING);
      }
    };

    const handleDragLeave = (event: DragEvent) => {
      if (event.clientX === 0 && event.clientY === 0) {
        setStatus(DragStatus.NONE);
      }
    };

    const handleDrop = () => setStatus(DragStatus.NONE);

    const handlePaste = (event: ClipboardEvent) => {
      if (event.clipboardData?.files.length) {
        const { files } = event.clipboardData;
        [...files].forEach((file) => {
          void addUploadTask(file, recordIds ?? [recordId], propertyId);
        });
      }
    };

    document.addEventListener('dragenter', handleDragEnter);
    document.addEventListener('dragleave', handleDragLeave);
    document.addEventListener('drop', handleDrop);
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('dragenter', handleDragEnter);
      document.removeEventListener('dragleave', handleDragLeave);
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('paste', handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hiddenList = fileSegments.length <= 0 && uploadingTasks.length <= 0;

  return (
    <div className="pt-1">
      {!readonly && (
        <Tooltip
          popup="支持多选，你也可以拖放文件到这里来上传"
          className={cx(status !== DragStatus.NONE && 'h-[140px]')}
        >
          <UploadArea
            ref={inputRef}
            className={cx(
              'relative flex items-center w-full animate-hover rounded-none',
              status === DragStatus.NONE
                ? 'h-10 px-3 py-2'
                : 'm-2.5 flex-col justify-center rounded',
              status === DragStatus.DRAGGING_OVER &&
                'bg-active_color_10 border-active_color border border-dashed'
            )}
            multiple
            onSelect={(files) => {
              [...files].forEach((file) => {
                void addUploadTask(file, recordIds ?? [recordId], propertyId);
              });
            }}
          >
            <Icon
              className={cx(status === DragStatus.NONE ? 'mr-1' : 'mb-2 text-grey3')}
              name={status === DragStatus.NONE ? 'IcAdd' : 'IcBtnUpload'}
              size={status === DragStatus.NONE ? 'middle' : 'large'}
            />
            <span className="text-t2 text-grey3">{[DragTip[status]]}</span>
          </UploadArea>
        </Tooltip>
      )}

      {hiddenList && <div className="text-t2 text-grey3 p-2">无文件</div>}
      <div
        className={cx(
          'max-h-[438px] overflow-auto py-2 border-b-[1px]',
          hiddenList && 'hidden',
          status !== DragStatus.NONE && 'hidden'
        )}
      >
        <div className="w-[260px]">
          <ImagesProvider>
            <SortableList
              disabled={readonly}
              items={fileSegments.map((it) => ({
                id: it.url ?? '<never>',
                data: it,
              }))}
              onChange={(items) => {
                updatePropertyValue(
                  recordId,
                  propertyId,
                  items.map((it) => it.data)
                );
              }}
              renderItemContent={({ item: { data }, renderDragHandle }) => (
                <FileItem
                  data={data}
                  renderDragHandle={renderDragHandle}
                  recordId={recordId}
                  recordIds={recordIds}
                  propertyId={propertyId}
                />
              )}
            />
          </ImagesProvider>
          <div>
            {uploadingTasks.map((task, index) => {
              return <UploadingFile key={index} task={task} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const FileItem: FC<{
  data: SegmentDTO;
  renderDragHandle: any;
  recordId: string;
  recordIds?: string[];
  propertyId: string;
}> = ({ data, renderDragHandle, recordIds, recordId, propertyId }) => {
  const canPreView = canPreViewImage(data.url);
  const openFile = useOpenFile();
  const openModal = useOpenModal();
  const [showInput, setShowInput] = useState(false);
  const { name: originName, extName } = getFileNameInfo(data.text);
  const [name, setName] = useState(originName);
  const updatePropertyValue = useUpdatePropertyValue();

  const openMore = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.target as HTMLElement,
      placement: 'right',
      content: DropdownMenu,
      contentProps: {
        recordIds,
        recordId,
        propertyId,
        data,
        setShowInput,
      },
    });
  };

  const submit = () => {
    const { blocks } = getState();
    const segments = blocks[recordId]?.data.collectionProperties?.[propertyId] ?? [];
    const fileSegments = segments.filter((item) => {
      return item.type === TextType.URL && item.fileStorageType === 'internal';
    });

    const newSegments = fileSegments.map((item) => {
      return {
        ...item,
        text: item.url === data.url && name.trim() ? combineFullName(name, extName) : item.text,
      };
    });

    updatePropertyValue(recordId, propertyId, newSegments);
    setShowInput(false);
  };

  return (
    <div className="flex flex-col px-3 bg-white2 cursor-pointer animate-hover-opaque">
      <div className="flex items-center w-full h-8">
        {renderDragHandle({ className: 'flex items-center justify-center w-5 h-5 text-grey4' })}

        {showInput ? (
          <Input
            selection={[0, data.text.length]}
            onEnter={submit}
            className="h-8 w-full max-w-[360px]"
            autoFocus
            value={name}
            onBlur={submit}
            onChange={(value) => setName(value)}
          />
        ) : (
          <>
            <span
              className="flex items-center flex-1 min-w-0 mx-1"
              onClick={() => {
                const isImage = FileRegex.image.test(extName);
                if (!isImage) {
                  void openFile(recordId, propertyId, data);
                }
              }}
            >
              <Icon className="mr-1" size="middle" name="IcTitleFiles" />
              <span
                title={data.text}
                className="flex-1 min-w-0 overflow-hidden text-t2 whitespace-nowrap overflow-ellipsis"
              >
                {data.text}
              </span>
            </span>

            <button
              className="flex items-center justify-center w-5 h-5 ml-1 text-grey3 animate-hover"
              onClick={openMore}
            >
              <Icon size="normal" className="w-5 h-5" name="IcMore" />
            </button>
          </>
        )}
      </div>

      {canPreView && (!data.size || data.size <= ALIYUN_IMGPROCESS_SIZE_LIMIT) && (
        <div
          className="w-full cursor-pointer flex justify-center"
          onClick={(e) => {
            e.stopPropagation();
            const isImage = FileRegex.image.test(extName);
            if (isImage) return;
            void openFile(recordId, propertyId, data);
          }}
        >
          <FilePreViewIcon
            className="max-h-[200px] mb-2"
            uuid={recordId}
            ossName={data.url}
            defaultIcon={<></>}
            options={{
              resizeWidth: 300,
              videoPlayIconSize: 'xxlarge',
              previewImage: FileRegex.image.test(extName),
            }}
          />
        </div>
      )}
    </div>
  );
};

interface UploadProps {
  multiple?: boolean;
  accept?: string;
  className?: string;
  onSelect?: (files: FileList) => void;
  children?: React.ReactNode;
}
export const UploadArea = forwardRef<HTMLInputElement, UploadProps>((props, ref) => {
  const { multiple, accept, className, children, onSelect } = props;
  const [key, setKey] = useState(0);
  return (
    <div className={className}>
      {children}
      <input
        ref={ref}
        key={key}
        type="file"
        multiple={multiple}
        accept={accept}
        className={css({
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 0,
          cursor: 'pointer',
          opacity: 0,
          width: '100%',
          height: '100%',
          selectors: {
            '::file-selector-button': {
              position: 'absolute',
              cursor: 'pointer',
              display: 'block',
              width: '100%',
              height: '100%',
            },
          },
        })}
        onChange={(event) => {
          if (event.target.files) {
            onSelect?.(event.target.files);
            setKey((key) => (key + 1) % 2);
          }
        }}
      />
    </div>
  );
});

const ImageGallery: FC<{ recordId: string; propertyId: string; data: SegmentDTO }> = ({
  recordId,
  propertyId,
  data,
}) => {
  const ownerId = useMemo(() => {
    return getOwnerPage(recordId);
  }, [recordId]);
  const imagesSegments = useMemo(() => {
    if (!ownerId) return [];
    const allSegments: SegmentDTO[] = [];
    const segments = cache.blocks[recordId]?.data.collectionProperties?.[propertyId] ?? [];
    const result = segments?.filter((s) => {
      const { extName } = getFileNameInfo(s.text);
      return FileRegex.image.test(extName);
    });
    allSegments.push(...result);
    return allSegments;
  }, [ownerId, propertyId, recordId]);

  const [index, setIndex] = useState(imagesSegments.findIndex((s) => fastEqual(s, data)));

  const gotoPreImage = useCallback(() => {
    setIndex((idx) => {
      if (idx === 0) {
        message.warning('当前为第一张');
        return idx;
      }
      return idx - 1;
    });
  }, []);
  const gotoNextImage = useCallback(() => {
    setIndex((idx) => {
      if (idx === imagesSegments.length - 1) {
        message.warning('当前为最后一张');
        return idx;
      }
      return idx + 1;
    });
  }, [imagesSegments.length]);

  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      if (isHotkey('ArrowLeft')(e)) {
        gotoPreImage();
      } else if (isHotkey('ArrowRight')(e)) {
        gotoNextImage();
      }
    };
    const remove = globalListenerHelper.addEventListener('keydown', keyDown);
    return remove;
  }, [gotoNextImage, gotoPreImage]);
  const segment = imagesSegments[index];
  if (!segment) return null;
  return (
    <div>
      <PreView recordId={recordId} data={segment} />
      {imagesSegments.length > 1 && (
        <>
          <Tooltip popup="上一张" className="cursor-pointer absolute left-0 pl-2 top-[50%]">
            <Icon
              name={'MIcBtnPre'}
              size="xxxlarge"
              onClick={(e) => {
                e.stopPropagation();
                gotoPreImage();
              }}
            />
          </Tooltip>
          <Tooltip popup="下一张" className="cursor-pointer absolute right-0 pr-2 top-[50%]">
            <Icon
              name={'MIcBtnNext'}
              size="xxxlarge"
              onClick={(e) => {
                e.stopPropagation();
                gotoNextImage();
              }}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};
const PreView: FC<{ recordId: string; propertyId?: string; data: SegmentDTO }> = ({
  recordId,
  propertyId,
  data,
}) => {
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const { extName } = getFileNameInfo(data.text);
  const Element = getPreviewComponent(extName);
  const closeModal = useCloseModal();
  useEffect(() => {
    if (!data.url) return;
    void urlFetcher
      .fetchDownloadUrl({
        blockId: recordId,
        ossName: data.url,
        fileName: data.text,
      })
      .then((res) => {
        const formatUrl = getFormatImageUrl(res, extName);
        setDownloadUrl(formatUrl);
      });
  }, [data.text, data.url, extName, recordId]);

  if (!downloadUrl) return null;

  return (
    <JotaiProvider
      scope={FILE_PREVIEW_SCOPE}
      initialValues={[[$filePreviewModel, { uuid: recordId, editor: false }]]}
    >
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-black-base/80">
        <div
          className="absolute top-0 left-0 z-10 flex items-center justify-end space-x-5 w-screen h-[55px] pr-5"
          style={{
            background: `linear-gradient(black, transparent)`,
          }}
        >
          <OfficeEditorBtn extName={extName} />
          <div
            className="text-white text-t2 animate-click"
            onClick={() => closeModal(Modals.FILE_PREVIEW)}
          >
            关闭
          </div>
        </div>
        <div
          className="flex items-center justify-center absolute top-[55px] bottom-0 w-screen"
          onClick={(event) =>
            event.target === event.currentTarget && closeModal(Modals.FILE_PREVIEW)
          }
        >
          <Element
            recordId={recordId}
            uuid={recordId}
            downloadUrl={downloadUrl}
            propertyId={propertyId}
          />
        </div>
      </div>
    </JotaiProvider>
  );
};
