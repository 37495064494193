import { ChartView, convertToData } from '@flowus/chart';
import type { NextBlock } from '@flowus/common/block/type';
import { NextModalProvider } from '@flowus/common/next-modal';
import type { BlockDTO } from '@next-space/fe-api-idl';
import type { EChartsType } from 'echarts';
import { isArrayLike } from 'lodash-es';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { ChartPageToolbar } from 'src/editor/component/chart/chart-page-toolbar';
import { ChartTitle } from 'src/editor/component/chart/chart-title';
import { useBlock } from 'src/hooks/block/use-block';
import { useChartTheme } from 'src/hooks/chart';
import { useTryFetchChartPerson } from 'src/hooks/chart/use-try-fetch-chart-person';
import { getTablePropertyValueForChart } from 'src/hooks/collection-view/get-property-value';
import { useGetSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import type { RootState } from 'src/redux/types';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { PageScene, PageSceneContext } from '../scene-context';
import { ErrorBlocker } from './error-blocker';
import { NumberChart } from './number-chart';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { emitter, getIsDarkMode, useIsDarkMode } from '@flowus/common';
import { segmentsToText } from 'src/editor/utils/editor';

interface Props {
  uuid: string;
}
export const PageChart: FC<Props> = (props) => {
  const format = useObservableBlock(props.uuid, (b) => b?.data.format?.chartFormat);

  return (
    <PageSceneContext.Provider value={PageScene.PAGE_CHART}>
      <NextModalProvider>
        <ErrorBlocker id={props.uuid}>
          <div className="w-full h-full flex flex-col relative group">
            <ChartTitle id={props.uuid} className="z-[11] text-h4 ml-4 mt-2" type="segments" />
            {format?.chartMode?.type === 'Number' && <NumberChart uuid={props.uuid} />}
            {format?.chartMode?.type !== 'Number' && <EChart uuid={props.uuid} />}
            <ChartPageToolbar
              blockId={props.uuid}
              className="z-[12] opacity-0 group-hover:opacity-100 next-modal absolute right-0 top-0"
            />
          </div>
        </ErrorBlocker>
      </NextModalProvider>
    </PageSceneContext.Provider>
  );
};

const EChart: FC<{ uuid: string }> = (props) => {
  const store = useStore();
  const block = useBlock(props.uuid);
  const collection = useBlock(block?.data.format?.chartFormat?.ref?.uuid);
  const format = block?.data.format?.chartFormat;
  const theme = useChartTheme(props.uuid);
  const seriesSizeRef = useRef(0);
  const [chartInstance, setChartInstance] = useState<EChartsType>();
  const tryFetchChartPerson = useTryFetchChartPerson();
  const [fetchPeople, setFetchPeople] = useState(false);
  const isDarkMode = useIsDarkMode();

  let sortedRecordIds = useGetSortedRecordIds(format?.ref?.viewId ?? '', [collection?.uuid]);

  if (!format?.ref?.viewId) {
    sortedRecordIds = collection?.subNodes ?? [];
  }

  const records = useObservableStore(
    (state) => {
      const { blocks } = state;
      return sortedRecordIds
        .map((id) => {
          return blocks[id];
        })
        .filter((v) => v) as NextBlock[];
    },
    [props.uuid, sortedRecordIds]
  );
  useEffect(() => {
    chartInstance?.renderToCanvas;
    if (!chartInstance) return;
    const on = () => {
      const url = chartInstance?.getConnectedDataURL({
        type: 'png',
        backgroundColor: getIsDarkMode() ? 'black' : 'white',
      });
      const fileName = `${segmentsToText(block?.data.segments)}.png` || '图片.png';
      url && void downloadUrlFile(url, fileName);
    };
    emitter.on('chart2png', on);
    return () => {
      emitter.off('chart2png', on);
    };
  }, [block?.data.segments, chartInstance]);

  useEffect(() => {
    if (collection?.uuid && sortedRecordIds) {
      void tryFetchChartPerson({
        collectionId: collection?.uuid,
        viewId: format?.ref?.viewId,
        recordIds: collection?.subNodes,
        store,
        callback: () => {
          setFetchPeople(true);
        },
      });
    }
  }, [
    collection?.uuid,
    format?.ref?.viewId,
    store,
    tryFetchChartPerson,
    collection?.subNodes,
    sortedRecordIds,
  ]);

  useEffect(() => {
    if (!chartInstance) return;
    if (!collection) return;
    if (!format) return;
    if (format.chartMode?.type === 'Number') return;
    const options = convertToData(
      collection as BlockDTO,
      records as BlockDTO[],
      format,
      (recordId, propertyId) => {
        return getTablePropertyValueForChart(recordId, propertyId, store.getState() as RootState);
      }
    );
    // @ts-ignore typecheck
    const data = options?.series?.[0]?.data;
    if (isArrayLike(data)) {
      /**
       * 当数据集有变化的时候，需要把nomerge设置为ture，重新渲染，这会导致动画失效。
       * 为了保证在数据集没变动，仅图表样式变动的情况下动画不失效，noMerge要设置为false
       */
      // 如果数据集没有变化，就直接merge，nomerge为true时动画会取消掉
      const noMerge = seriesSizeRef.current !== data.length;
      seriesSizeRef.current = data.length;
      // FIXME 先不考虑动画的问题,每次都创建新的
      options && chartInstance?.setOption(options, true);
    } else {
      // 理论上这里不会被执行
      console.warn('setOption issue');
      options && chartInstance?.setOption(options, true);
    }
  }, [chartInstance, collection, format, records, store, fetchPeople, isDarkMode]);

  return (
    <ChartView
      theme={theme}
      ref={(ref) => {
        if (ref) {
          setChartInstance(ref);
        }
      }}
    />
  );
};
