import { sleep } from '@flowus/common/async';
import type { PlanCouponDto, PlanDTO } from '@next-space/fe-api-idl';
import { useDebounceEffect } from 'ahooks';
import dayjs from 'dayjs';
import { first } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'src/common/components/message';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { createModel, useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { Modals } from 'src/modals';
import { useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { useUserBalance } from 'src/services/user/user-balance';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { getCurrencySymbols } from 'src/utils/currency-format';
import type { PatchState } from 'src/utils/type-utils';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import {
  useCloseSettingModal,
  useOpenSettingModal,
} from 'src/views/main/setting-modal/use-open-setting-modal';
import type { AsyncReturnType } from 'type-fest';
import { v4 } from 'uuid';
import type { SelectUpgradePlanProps } from '../common';
import { getCouponTypeByBizTracker, orderBizTracker } from '../common';
import { getPlanList, useGetCacheQuote } from '../hook/use-upgrade-support';
import { PayQrModal } from '../pay-qr-modal';
import { PayType } from '@flowus/common/utils/pay-info';
import { isMobile } from 'react-device-detect';
import { isInWechat } from '@flowus/common/url';
import { isInWeapp } from '@flowus/login/utils';
// #region type
interface State {
  planId?: string;
  selectPayType: PayType;
  selectCoupon?: string;
}
// #endregion

// #region context
const useUpgradePersonalPlan = (props: SelectUpgradePlanProps) => {
  const { isFrom, selectPlanType: selectPlanSpaceType } = props;
  const { integralInfo } = useUserBalance();
  const userIntegralRecord = integralInfo?.currentScore ?? 0;
  const { spacePlan } = useCurrentSpacePlanInfo();
  const upgradePlanName = getSpacePlanTypeName(selectPlanSpaceType);
  const openSettingModal = useOpenSettingModal();
  const closeSettingModal = useCloseSettingModal();
  const currentSpace = useCurrentSpace();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const isProSpace = useIsProSpace();
  const getCacheQuote = useGetCacheQuote();
  const [orderStateKey, setOrderStateKey] = useState('');
  // 后端返回套餐
  const [planList, setPlanList] = useState<PlanDTO[]>([]);

  // 优惠券列表
  const [couponList, setCouponList] = useState<PlanCouponDto[]>([]);
  // 当前选中的套餐和支付类型
  const [state, setState] = useState<State>({
    planId: planList[1]?.id,
    selectPayType: isInWechat ? PayType.wxpay : PayType.alipay,
    selectCoupon: undefined,
  });
  const [loading, setLoading] = useState(true);
  /** 后端计算的价格 */
  const [calculateQuote, setCalculateQuote] = useState<
    Record<string, AsyncReturnType<typeof request.infra.getQuote> & { planId: string }>
  >({});

  /** 查找价格 */
  const findPlanQuote = useCallback(
    (id?: string) => (id ? calculateQuote[id] : undefined),
    [calculateQuote]
  );

  const currentCalculateQuote = useMemo(
    () => findPlanQuote(state.planId),
    [findPlanQuote, state.planId]
  );

  /** 当前选中的套餐 */
  const curPlan = useMemo(
    () => planList.find((i) => i.id === state.planId),
    [planList, state.planId]
  );

  /** 当前套餐的价格 */
  const curPlanQuote = useMemo(() => findPlanQuote(state.planId), [state.planId, findPlanQuote]);
  /** 当前选中的套餐是年还是月 */
  const curPlanIsYear = (curPlan?.monthNum ?? 0) >= 12;
  /** 是不是自动续费套餐 */
  const isCycle = curPlan?.spacePlanType?.toLocaleLowerCase()?.includes('cycle');
  /** 是不是教育优惠套餐 */
  const isStuDiscountCash = curPlan?.spacePlanType === 'stuDiscountCash';
  /** 选中的优惠券 */
  const curCoupon = couponList.find((i) => i.id === state.selectCoupon);

  // 更新state
  const patchState: PatchState<typeof state> = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  // 关闭弹窗
  const close = () => {
    closeModal(Modals.SELECT_UPDATE_PLAN);
  };

  // 更新套餐列表
  const patchSelectPlanList = async () => {
    setLoading(true);
    const res = await getPlanList(currentSpace.uuid, 'personal', state.selectPayType);
    setPlanList(res.list ?? []);
    // 如果新套餐里没有当前这个套餐，就重新选择中第一个
    if (!res.list?.some((it) => it.id === state.planId)) {
      const list = (res.list as PlanDTO[])?.filter((it) => {
        if (isInWechat) {
          // 微信内不显示连续包月/年
          const isCycle = it?.spacePlanType?.toLocaleLowerCase()?.includes('cycle');
          return !isCycle && it.enabled !== 2;
        }
        return it.enabled !== 2;
      });
      const [monthPlan, yearPlan] = list;

      if (__BUILD_IN__ && yearPlan) {
        patchState('planId', yearPlan.id);
      } else {
        patchState('planId', monthPlan?.id);
      }
    }
    setLoading(false);
  };

  // 更新优惠券列表
  const patchCouponList = async () => {
    if (!state.planId) return;
    const couponListRes = await request.infra.getCouponList(
      state.planId,
      undefined,
      currentSpace.uuid
    );
    setCouponList(couponListRes.list ?? []);
    const firstCoupon = first(
      couponListRes.list?.filter((i) => (i.integralRecord ?? 0) <= userIntegralRecord)
    );
    if (!firstCoupon) {
      patchState('selectCoupon', '');
      return;
    }
    if (!couponListRes.list?.some((it) => it.id === state.selectCoupon)) {
      patchState('selectCoupon', firstCoupon.id);
    }
  };

  // 计算价格
  const patchQuote = async () => {
    const { planId } = state;
    if (planId && currentSpace.uuid) {
      const res = await getCacheQuote({
        spaceId: currentSpace.uuid,
        planId,
        couponId: state.selectCoupon,
        date: curPlan?.monthNum ?? 0,
        people: 1,
      });
      setCalculateQuote((pre) => ({ ...pre, [planId]: { ...res, planId } }));
      setOrderStateKey(v4());
    }
  };

  // 发送订单埋点，抽出来是因为有三个地方使用
  const sendOrderBizTracker = (bizType: 'order_finish' | 'pay_order') => {
    orderBizTracker({
      bizType,
      order_amount: curPlanQuote?.amount,
      order_month: curPlan?.monthNum,
      order_channel:
        state.selectPayType === PayType.irpay
          ? 'point'
          : state.selectPayType === PayType.alipay
          ? 'zhifubao'
          : 'weixin',
      is_from: isFrom,
      order_type: isCycle ? 'repeat' : 'single',
      order_new: isProSpace ? 'yes' : 'no',
      coupon_amount: curCoupon?.salePrice,
      pay_point: curCoupon?.integralRecord,
      coupon_type: getCouponTypeByBizTracker(curCoupon?.type),
      space_current_type: currentSpace.planType,
      space_target_type: selectPlanSpaceType,
    });
  };

  const checkoutOrder = async () => {
    let payType = state.selectPayType;
    // 由于获取套餐还是web的逻辑，所以只能在最后调用接口的时候替换为h5 alipay
    if (isMobile) {
      // if (payType === PayType.alipay) {
      //   payType = PayType.alipayH5;
      // } else
      if (payType === PayType.wxpay) {
        if (isInWeapp()) {
          payType = PayType.minipay;
        } else if (isInWechat) {
          payType = PayType.wxjsapi;
        } else {
          payType = PayType.wxpayH5;
        }
      }
    }
    const res = await request.infra.createPreOrderV2.raw({
      payType,
      planId: `${state.planId}`,
      spaceId: currentSpace.uuid,
      couponId: state.selectCoupon,
    });
    setLoading(false);
    if (res.code === 200) {
      return res.data;
    }
    throw new Error('下单失败');
  };

  // 点击支付
  const clickPay = async () => {
    if (!state.planId) {
      message.warning('请选择套餐');
      return;
    }
    setLoading(true);
    message.loading('正在创建订单');
    sendOrderBizTracker('pay_order');

    const createOrder = await checkoutOrder();

    if (!createOrder.orderId) {
      return message.error('下单失败，请重试');
    }
    // 现金支付
    openModal.modal({
      modalId: Modals.PAY_MODAL,
      content: () => (
        <PayQrModal
          rePayOrder={checkoutOrder}
          onFinish={async () => {
            sendOrderBizTracker('order_finish');
            close();
            closeSettingModal();
            await sleep(500);
            openSettingModal({
              defaultMenuType: SettingMenuType.upgrade,
              from: OpenSettingFrom.pay,
            });
          }}
          payType={state.selectPayType}
          priceTitle={`${getCurrencySymbols(curPlanQuote?.amountUnit)}${createOrder.amount}`}
          orderId={`${createOrder.orderId}`}
          qrCodeUrl={`${createOrder.qrCodeUrl}`}
        />
      ),
    });
  };

  // 支付按钮状态
  const payButtonDisable = useMemo(() => {
    if (loading) return true;
    if (state.planId && curPlanQuote?.amount) {
      return false;
    }
    return true;
  }, [loading, state.planId, curPlanQuote?.amount]);

  // 从后端获取套餐的价格
  useEffect(() => {
    void patchSelectPlanList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace.uuid, state.selectPayType]);

  // 自动更新优惠券列表
  useEffect(() => {
    void patchCouponList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.planId]);

  // 自动计算价格
  useDebounceEffect(
    () => {
      void patchQuote();
    },
    [state.planId, state.selectCoupon],
    { wait: 100 }
  );

  /** 套餐到期时间 */
  const lastDate = useMemo(() => {
    const lastDay = dayjs(curPlanQuote?.desiredExpireTime || spacePlan?.endAt || Date.now());
    return lastDay.format('YYYY年MM月DD日');
  }, [curPlanQuote?.desiredExpireTime, spacePlan?.endAt]);

  return {
    sendOrderBizTracker,
    currentSpace,
    selectPlanSpaceType,
    state,
    patchState,
    isCycle,
    curPlanQuote,
    payButtonDisable,
    clickPay,
    planList,
    couponList,
    calculateQuote,
    lastDate,
    curPlan,
    curCoupon,
    curPlanIsYear,
    isStuDiscountCash,
    close,
    loading,
    orderStateKey,
    checkoutOrder,
    upgradePlanName,
    currentCalculateQuote,
  };
};

export const UpgradePersonalPlanContext = createModel(useUpgradePersonalPlan);
export const useUpgradePersonalPlanContext = () => useModel(UpgradePersonalPlanContext);
// #endregion
