import { getFormatImageUrl } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { canPreViewImage, isOfficePreView } from '@flowus/common/file-preview/can-preview-image';
import { useAsyncComputation } from '@flowus/common/hooks/react-utils';
import { FileRegex } from '@flowus/common/regex';
import { ossImageSnapshotUrl, ossVideoSnapshotUrl } from '@flowus/common/url';
import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import { omit } from 'lodash-es';
import type { FC, ReactNode } from 'react';
import { memo, useEffect, useRef } from 'react';
import { Img, useImage } from 'react-image';
import { PhotoView } from 'react-photo-view-kcsx';
import type { IconSizeStyle } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { request } from 'src/common/request';
import { compressImageSupport } from 'src/common/utils/url-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { $currentUserCache } from 'src/services/user/current-user';
import { getFileNameInfo } from 'src/utils/file';
import { urlFetcher } from 'src/utils/url-fetcher';

/** README
 *  文件小图预览组件
 *  组件分为了三层
 *  第一层是对外暴露的。用于判断文件类型是否可以使用预览
 *  第二层通过useResource加载出资源，在loading前依旧保持原defaultIcon样式，也就是占位样式
 *  第三层是真正加载出组件，LazyImage；但他在正确渲染出图片前，依旧会保持defaultIcon样式
 *
 *  有了以上三层，如果文件可以被预览，就一定会出预览图，不会出现加载失败的情况
 */

type ImgProps = JSX.IntrinsicElements['img'];

interface FilePreViewIconProps extends ImgProps {
  uuid: string;
  defaultIcon: ReactNode;
  options?: {
    /** 压缩图片宽度 */
    resizeWidth?: number;
    /** 建议是 xxxlarge 或 small */
    videoPlayIconSize?: keyof typeof IconSizeStyle;
    hideVideoIcon?: boolean;
    onError?: () => void;
    previewImage?: boolean;
    useOverlayId?: boolean;
  };
}

// #region ossName生成预览失败，更新ossName
class ClassPatchErrorOssNameApi {
  public errorOssName: Set<string> = new Set();
  private doneErrorOssNames: Set<string> = new Set();
  private timer: Timeout | null = null;

  public add(uuid: string): void {
    if (__BUILD_IN__ || __PRIVATE__) return;
    if (this.doneErrorOssNames.has(uuid) || !$currentUserCache.uuid) return;

    this.errorOssName.add(uuid);
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.errorOssName.size > 0) {
        void request.editor.filesPreviewOssProcess.raw({ ossNames: [...this.errorOssName] });
        this.doneErrorOssNames = new Set([...this.errorOssName, ...this.doneErrorOssNames]);
        this.errorOssName.clear();
      }
    }, 1000);
  }
}
const patchErrorOssNameApi = new ClassPatchErrorOssNameApi();
// #endregion

// #region 第一层 对外组件
export const FilePreViewIcon: FC<FilePreViewIconProps & { ossName?: string }> = memo((props) => {
  const { defaultIcon, uuid, ossName } = props;
  const newOssName = canPreViewImage(ossName);
  // 被风控了
  const { illegal } = usePermissions(uuid);

  const { extName } = getFileNameInfo(ossName);

  if (!ossName || !extName || illegal) {
    return <>{defaultIcon}</>;
  }

  if (!newOssName) {
    return <>{defaultIcon}</>;
  }

  return (
    <ResourceFc
      {...props}
      extName={getFileNameInfo(newOssName).extName}
      ossName={newOssName}
      originOssName={ossName}
    />
  );
});
// #endregion

// #region 第二层 加载资源
const ResourceFc: FC<
  FilePreViewIconProps & { extName: string; ossName: string; originOssName: string }
> = memo((props) => {
  const { uuid, defaultIcon, className, options, ossName, extName } = props;
  const noThumbnails = !compressImageSupport.test(extName);
  const resizeWidth = Math.floor(options?.resizeWidth ?? 200);
  const [status, url] = useAsyncComputation(
    () => urlFetcher.fetchImageUrl({ blockId: uuid, ossName }),
    [uuid, ossName]
  );

  useEffect(() => {
    if (status === 'fulfilled' && !url) {
      options?.onError?.();
    }
  }, [options, status, url]);

  if (status !== 'fulfilled' || !url) {
    return <>{defaultIcon}</>;
  }

  // image
  if (FileRegex.image.test(extName)) {
    return (
      <LazyImage
        {...props}
        url={noThumbnails ? url : getFormatImageUrl(ossImageSnapshotUrl(url, resizeWidth), extName)}
        originSrc={url}
        className={className}
        defaultIcon={defaultIcon}
        options={options}
        fileType="image"
      />
    );
  }

  // video
  if (FileRegex.video.test(extName)) {
    return (
      <LazyImage
        {...props}
        originSrc={ossVideoSnapshotUrl(url)}
        url={ossVideoSnapshotUrl(url)}
        className={className}
        defaultIcon={defaultIcon}
        fileType="video"
        options={options}
      />
    );
  }

  return <>{defaultIcon}</>;
});
// #endregion

// #region 第三层 加载图片
export const LazyImage: FC<
  {
    url: string;
    fileType?: 'video' | 'image';
    originOssName?: string;
    uuid?: string;
    originSrc?: string;
  } & Omit<FilePreViewIconProps, 'uuid'>
> = (props) => {
  const patchApiErrorOssName = useRef(false);
  const { url, className, defaultIcon, fileType, options, originOssName, originSrc, ...reset } =
    props;
  const { src, error } = useImage({ srcList: url, useSuspense: false });

  useEffect(() => {
    if (error && originOssName) {
      options?.onError?.();
      const { extName } = getFileNameInfo(originOssName);
      if (isOfficePreView(extName)) {
        patchApiErrorOssName.current = true;
        patchErrorOssNameApi.add(originOssName);
      }
    }
  }, [error, options, originOssName]);

  const content = (
    <Img
      {...omit(reset, ['uuid', 'extName', 'ossName'])}
      src={`${src}`}
      loader={<>{defaultIcon}</>}
      unloader={<>{defaultIcon}</>}
      className={cx('object-contain object-center', className)}
    />
  );

  switch (fileType) {
    case 'video':
      if (options?.hideVideoIcon || error) {
        return content;
      }
      return (
        <div className="relative flex items-center justify-center">
          {content}
          <Icon
            name="IcMenuNext"
            size={options?.videoPlayIconSize ?? 'normal'}
            className="absolute text-black-base pointer-events-none"
          />
        </div>
      );
    case 'image':
      if (options?.previewImage) {
        return (
          <PhotoView
            src={originSrc}
            overlay={options?.useOverlayId ? { uuid: reset.uuid } : {}}
            render={() => content}
          >
            {content}
          </PhotoView>
        );
      }
      return content;
    default:
      return content;
  }
};
// #endregion
